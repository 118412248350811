._fteEnum_1tr9x_1 {
  display: flex;
  flex-direction: column;
}
._fteEnum_1tr9x_1 ._fteItem_1tr9x_5 {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--Light-Background-White, #fff);
}
._fteEnum_1tr9x_1 ._fteItem_1tr9x_5 ._row_1tr9x_13 ._label_1tr9x_13 {
  display: flex;
  align-items: center;
}
._fteEnum_1tr9x_1 ._deleteButton_1tr9x_17 {
  border: transparent;
}
._fteEnum_1tr9x_1 ._addButton_1tr9x_20 {
  width: 100%;
  margin-top: 16px;
}