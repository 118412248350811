._steps_f4w7p_1 {
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.08), 0 0.5px 2px rgba(96, 97, 112, 0.16);
  padding: 0 43px;
}
._steps_f4w7p_1 li {
  list-style: none;
  color: var(--gray-400);
  font-size: 12px;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
}
._steps_f4w7p_1 li._lock_f4w7p_16 > span::after {
  content: "";
  width: 10px;
  height: 12px;
  line-height: 12px;
  background: url("./assets/icon-lock.svg");
  display: inline-block;
  background-size: cover;
  color: var(--gray-400);
  fill: var(--gray-400);
  margin-left: 4px;
}
._steps_f4w7p_1 li._linkBack_f4w7p_28 {
  cursor: pointer;
}
._steps_f4w7p_1 li._linkBack_f4w7p_28:hover {
  color: var(--primary-7);
  text-decoration: underline;
}
._steps_f4w7p_1 li > span {
  display: inline-block;
  border-bottom: #fff 1px solid;
  padding: 14px 6px 14px 0;
  flex: 0 auto;
}
._steps_f4w7p_1 li > span > span {
  display: inline-block;
  height: 20px;
  width: 20px;
  border: var(--gray-400) 1px solid;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  margin: 0 5px 0 0;
}
._steps_f4w7p_1 li i {
  vertical-align: middle;
  flex: 1 auto;
  text-align: center;
  padding-top: 5px;
}
._steps_f4w7p_1 li._active_f4w7p_57 {
  color: var(--primary-7);
  font-weight: 500;
}
._steps_f4w7p_1 li._active_f4w7p_57 > span {
  border-bottom: var(--primary-7) 2px solid;
  border-radius: 2px;
}
._steps_f4w7p_1 li._active_f4w7p_57 > span > span {
  border-color: var(--primary-7);
}
._steps_f4w7p_1 li._actived_f4w7p_68 {
  color: var(--gray-700);
}
._steps_f4w7p_1 li._actived_f4w7p_68 > span > span {
  color: #0092e4;
  background-color: #06c270;
  border-color: #06c270;
}
._steps_f4w7p_1 li._actived_f4w7p_68 > span > span svg {
  width: 13px;
  height: 9px;
}