._filters_1samd_1 {
  padding: 0 48px 18px;
  display: flex;
  flex-direction: row;
  font-weight: 500;
}
._filters_1samd_1 li {
  list-style: none;
  margin-right: 2em;
}
._filters_1samd_1 .ant-input-number,
._filters_1samd_1 .ant-select {
  width: 120px;
}

._loading_1samd_16 {
  height: 50vh;
}

._reportingContainer_1samd_20 {
  padding: 0 48px;
}
._reportingContainer_1samd_20 ul {
  padding-left: 1em;
}
._reportingContainer_1samd_20 ._moduleIcon_1samd_26 {
  height: 20px;
  width: auto;
  margin-right: 4px;
}
._reportingContainer_1samd_20 h3 {
  font-size: 18px;
  font-weight: 700;
  border-radius: 5px;
  padding: 4px 8px;
  margin-top: 48px;
}
._reportingContainer_1samd_20 h4 {
  font-size: 16px;
  font-weight: 600;
}
._reportingContainer_1samd_20 ._total_1samd_42 {
  font-size: 18px;
  font-weight: 700;
}
._reportingContainer_1samd_20 > ul {
  padding-left: 0;
}
._reportingContainer_1samd_20 > ul > li {
  margin-top: 1em;
  list-style: none;
  margin: 0;
  padding: 0;
}
._reportingContainer_1samd_20 > ul > li > ul {
  margin-left: 26px;
}
._reportingContainer_1samd_20 > ul > li > ul > li {
  margin-top: 1em;
}
._reportingContainer_1samd_20 ._ci_1samd_61 h3,
._reportingContainer_1samd_20 ._ci_1samd_61 h4 {
  color: var(--mod-ci-300);
}
._reportingContainer_1samd_20 ._ci_1samd_61 h3 {
  border: 1px solid var(--mod-ci-200);
  background-color: var(--mod-ci-100);
}
._reportingContainer_1samd_20 ._ci_1samd_61 ._tableRow_1samd_69 {
  background-color: var(--mod-ci-100);
}
._reportingContainer_1samd_20 ._cd_1samd_72 h3,
._reportingContainer_1samd_20 ._cd_1samd_72 h4 {
  color: var(--mod-cd-300);
}
._reportingContainer_1samd_20 ._cd_1samd_72 h3 {
  border: 1px solid var(--mod-cd-200);
  background-color: var(--mod-cd-100);
}
._reportingContainer_1samd_20 ._cd_1samd_72 ._tableRow_1samd_69 {
  background-color: var(--mod-cd-100);
}
._reportingContainer_1samd_20 ._ff_1samd_83 h3,
._reportingContainer_1samd_20 ._ff_1samd_83 h4 {
  color: var(--mod-ff-300);
}
._reportingContainer_1samd_20 ._ff_1samd_83 h3 {
  border: 1px solid var(--mod-ff-200);
  background-color: var(--mod-ff-100);
}
._reportingContainer_1samd_20 ._ff_1samd_83 ._tableRow_1samd_69 {
  background-color: var(--mod-ff-100);
}
._reportingContainer_1samd_20 ._cc_1samd_94 h3,
._reportingContainer_1samd_20 ._cc_1samd_94 h4,
._reportingContainer_1samd_20 ._ccm_1samd_96 h3,
._reportingContainer_1samd_20 ._ccm_1samd_96 h4 {
  color: var(--mod-cc-300);
}
._reportingContainer_1samd_20 ._cc_1samd_94 h3,
._reportingContainer_1samd_20 ._ccm_1samd_96 h3 {
  border: 1px solid var(--mod-cc-200);
  background-color: var(--mod-cc-100);
}
._reportingContainer_1samd_20 ._cc_1samd_94 ._tableRow_1samd_69,
._reportingContainer_1samd_20 ._ccm_1samd_96 ._tableRow_1samd_69 {
  background-color: var(--mod-cc-100);
}
._reportingContainer_1samd_20 ._sto_1samd_109 h3,
._reportingContainer_1samd_20 ._sto_1samd_109 h4 {
  color: var(--mod-sto-300);
}
._reportingContainer_1samd_20 ._sto_1samd_109 h3 {
  border: 1px solid var(--mod-sto-200);
  background-color: var(--mod-sto-100);
}
._reportingContainer_1samd_20 ._sto_1samd_109 ._tableRow_1samd_69 {
  background-color: var(--mod-sto-100);
}
._reportingContainer_1samd_20 ._srm_1samd_120 h3,
._reportingContainer_1samd_20 ._srm_1samd_120 h4 {
  color: var(--mod-srm-300);
}
._reportingContainer_1samd_20 ._srm_1samd_120 h3 {
  border: 1px solid var(--mod-srm-200);
  background-color: var(--mod-srm-100);
}
._reportingContainer_1samd_20 ._srm_1samd_120 ._tableRow_1samd_69 {
  background-color: var(--mod-srm-100);
}
._reportingContainer_1samd_20 ._ce_1samd_131 h3,
._reportingContainer_1samd_20 ._ce_1samd_131 h4 {
  color: var(--mod-ce-300);
}
._reportingContainer_1samd_20 ._ce_1samd_131 h3 {
  border: 1px solid var(--mod-ce-200);
  background-color: var(--mod-ce-100);
}
._reportingContainer_1samd_20 ._ce_1samd_131 ._tableRow_1samd_69 {
  background-color: var(--mod-ce-100);
}
._reportingContainer_1samd_20 ._cet_1samd_142 h3,
._reportingContainer_1samd_20 ._cet_1samd_142 h4 {
  color: var(--mod-cet-300);
}
._reportingContainer_1samd_20 ._cet_1samd_142 h3 {
  border: 1px solid var(--mod-cet-200);
  background-color: var(--mod-sei-100);
}
._reportingContainer_1samd_20 ._cet_1samd_142 ._tableRow_1samd_69 {
  background-color: var(--mod-cet-100);
}
._reportingContainer_1samd_20 ._sei_1samd_153 h3,
._reportingContainer_1samd_20 ._sei_1samd_153 h4 {
  color: var(--mod-sei-300);
}
._reportingContainer_1samd_20 ._sei_1samd_153 h3 {
  border: 1px solid var(--mod-sei-200);
  background-color: var(--mod-sei-100);
}
._reportingContainer_1samd_20 ._sei_1samd_153 ._tableRow_1samd_69 {
  background-color: var(--mod-sei-100);
}
._reportingContainer_1samd_20 ._moduleCharts_1samd_164 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 20px;
}
._reportingContainer_1samd_20 ._moduleCharts_1samd_164 li {
  gap: 8px;
}