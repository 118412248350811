._integerStepFormItemDiv_tjw9o_1 {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 4px;
  border: 1px solid var(--Background-white-or-black, #fff);
  background: var(--Background-white-or-black, #fff);
  gap: 24px;
}

._integerStepFormItemLabel_tjw9o_11 {
  color: var(--Text-primary, #0b0b0d);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

._integerStep_tjw9o_1 {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 13px;
}
._integerStep_tjw9o_1 ._slider_tjw9o_27 {
  flex-shrink: 1;
  flex-grow: 1;
  margin-top: 13px;
}
._integerStep_tjw9o_1 ._inputNumber_tjw9o_32 {
  flex-basis: 95px;
  flex-shrink: 0;
  margin-top: -13px;
}

._SliderRoot_tjw9o_38 {
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  cursor: pointer;
}

._SliderLabelDiv_tjw9o_47 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

._SliderLabelFont_tjw9o_54 {
  color: var(--Gray-Scale-Gray-Scale-300, var(--Gray-Scale-Gray-Scale-300, #b0b1c3));
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.2px;
}

._SliderTrack_tjw9o_64 {
  background-color: var(--primary-1);
  position: relative;
  flex-grow: 1;
  height: 2px;
}

._SliderRange_tjw9o_71 {
  position: absolute;
  background-color: var(--primary-7);
  height: 100%;
}

._SliderThumb_tjw9o_77 {
  display: block;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  border-radius: 100px;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(40, 41, 61, 0.2), 0 2px 4px 0 rgba(96, 97, 112, 0.2);
}

._SliderThumb_tjw9o_77:hover {
  outline: none;
  box-shadow: 0 0 0 1px var(--primary-7);
}