:root {
  /* Primary colors - for links, buttons, etc. */
  --primary-10: #07182b;
  --primary-9: #0a3364; /* Darker Primary */
  --primary-8: #004ba4;
  --primary-7: #0278d5; /* Main Primary */
  --primary-6: #0092e4;
  --primary-5: #00ade4; /* Brand Primary */
  --primary-4: #3dc7f6;
  --primary-3: #a3e9ff; /* Lighter Primary */
  --primary-2: #cdf4fe;
  --primary-1: #effbff;
  /* Text & Background - Gray Scale */
  --gray-1000: #000;
  --gray-900: #0b0b0d;
  --gray-800: #22222a;
  --gray-700: #383946;
  --gray-600: #4f5162;
  --gray-500: #6b6d85; /* Main */
  --gray-400: #9293ab;
  --gray-300: #b0b1c4;
  --gray-200: #d9dae5;
  --gray-100: #f3f3fa;
  --gray-50: #fafbfc; /* Background */
  --gray-0: #fff;
  /* Alerts & Statues - Green */
  --green-900: #1e5c1f;
  --green-800: #1b841d;
  --green-700: #299b2c;
  --green-600: #42ab45;
  --green-500: #4dc952; /* Main */
  --green-400: #86d981;
  --green-300: #a2e29b;
  --green-200: #bdeab7;
  --green-100: #d8f3d4;
  --green-50: #e4f7e1; /* Background */
  /* Alerts & Statues - Cornflower Blue */
  --blue-900: #2d376d;
  --blue-800: #39478f;
  --blue-700: #4c5cb0;
  --blue-600: #6374d0;
  --blue-500: #798bec; /* Main */
  --blue-400: #8598ff;
  --blue-300: #97a7ff;
  --blue-200: #b1beff;
  --blue-100: #dae0ff;
  --blue-50: #f4f6ff; /* Background */
  /* Alerts & Statues - Yellow */
  --yellow-900: #fcb519;
  --yellow-800: #fcc026;
  --yellow-700: #fcc62d;
  --yellow-600: #fdcc35;
  --yellow-500: #fdd13b; /* Main */
  --yellow-400: #fedf76;
  --yellow-300: #fee89d;
  --yellow-200: #fef1c4;
  --yellow-100: #fff9e7;
  --yellow-50: #fffbee; /* Background */
  /* Alerts & Statues - Orange */
  --orange-900: #ff5310;
  --orange-800: #ff661a;
  --orange-700: #ff7020;
  --orange-600: #ff7b26;
  --orange-500: #ff832b; /* Main */
  --orange-400: #ffa86b;
  --orange-300: #ffc195;
  --orange-200: #ffdabf;
  --orange-100: #fff0e6;
  --orange-50: #fff5ed; /* Background */
  /* Alerts & Statues - Red */
  --red-900: #b41710;
  --red-800: #c41f17;
  --red-700: #cf2318;
  --red-600: #da291d;
  --red-500: #e43326; /* Main */
  --red-400: #ee5f54;
  --red-300: #ef9790;
  --red-200: #f5c0bc;
  --red-100: #fbe6e4;
  --red-50: #fcedec; /* Background */
  /* Secondary & Charts - Teal */
  --teal-900: #07a0ab;
  --teal-800: #05aab6;
  --teal-700: #06b7c4;
  --teal-600: #03c0cd;
  --teal-500: #0bc8d6; /* Main */
  --teal-400: #47d5df;
  --teal-300: #73dfe7;
  --teal-200: #a9eff2;
  --teal-100: #c0fbfe;
  --teal-50: #d3fcfe; /* Background */
  /* Secondary & Charts - Lime Green */
  --lime-900: #487a34;
  --lime-800: #558b2f;
  --lime-700: #689f38;
  --lime-600: #76af34;
  --lime-500: #7fb800; /* Main */
  --lime-400: #9ccc65;
  --lime-300: #aadc72;
  --lime-200: #c5e1a5;
  --lime-100: #eaf8db;
  --lime-50: #f1fae6; /* Background */
  /* Secondary & Charts - Purple */
  --purple-900: #4d0b8f;
  --purple-800: #4d278f;
  --purple-700: #592baa;
  --purple-600: #6938c0;
  --purple-500: #6938c0; /* Main */
  --purple-400: #ae82fc;
  --purple-300: #c19eff;
  --purple-200: #cfb4ff;
  --purple-100: #e1d0ff;
  --purple-50: #eadeff; /* Background */
  /* Secondary & Charts - Magenta */
  --magenta-900: #ca136c;
  --magenta-800: #d91f79;
  --magenta-700: #ee2a89;
  --magenta-600: #f53693;
  --magenta-500: #ff479f; /* Main */
  --magenta-400: #ff8ac1;
  --magenta-300: #ffabd3;
  --magenta-200: #ffcde4;
  --magenta-100: #ffeef7;
  --magenta-50: #fff3f9; /* Background */
  /* ----- Colors ----- */
  --white: var(--gray-0);
  --black: var(--gray-1000);
  --gray: var(--gray-500);
  --gray-bg: var(--gray-50);
  --primary-darker: var(--primary-9);
  --primary-main: var(--primary-7);
  --primary: var(--primary-main);
  --primary-brand: var(--primary-5);
  --primary-lighter: var(--primary-3);
  --primary-bg: #fafcff;
  --green: var(--green-500);
  --green-bg: var(--green-50);
  --blue: var(--blue-500);
  --blue-bg: var(--blue-50);
  --yellow: var(--yellow-500);
  --yellow-bg: var(--yellow-50);
  --orange: var(--orange-500);
  --orange-bg: var(--orange-50);
  --red: var(--red-500);
  --red-bg: var(--red-50);
  --teal: var(--teal-500);
  --teal-bg: var(--teal-50);
  --lime: var(--lime-500);
  --lime-bg: var(--lime-50);
  --purple: var(--purple-500);
  --purple-bg: var(--purple-50);
  --magenta: var(--magenta-500);
  --magenta-bg: var(--magenta-50);
  --page-bg: #f8fcff;
  --header-bg: #1a1a1a;
  /* module colors */
  --ci-theme-bg: #eff9ff;
  --cd-theme-bg: #edffea;
  --cloud-cost-theme-bg: #dcfffa;
  --feature-flags-theme-bg: #ffefe4;
  --ch-intel-theme-bg: #f6f1ff;
  --ci-theme-color: #a4cdf8;
  --cd-theme-color: #6fd43f;
  /* Module Colors/Continuous Delivery/300 */
  --mod-cd-300: #30841f;
  /* Module Colors/Continuous Delivery/200 */
  --mod-cd-200: #5fb34e;
  /* Module Colors/Continuous Delivery/100 */
  --mod-cd-100: #f6fff2;
  /* Module Colors/Continuous Integration/300 */
  --mod-ci-300: #0672b6;
  /* Module Colors/Continuous Integration/200 */
  --mod-ci-200: #2bb1f2;
  /* Module Colors/Continuous Integration/100 */
  --mod-ci-100: #e2f5ff;
  /* Module Colors/Cloud Cost/300 */
  --mod-cc-300: #24807f;
  /* Module Colors/Cloud Cost/200 */
  --mod-cc-200: #01c9cc;
  /* Module Colors/Cloud Cost/100 */
  --mod-cc-100: #ecffff;
  /* Module Colors/Feature Flags/300 */
  --mod-ff-300: #c05809;
  /* Module Colors/Feature Flags/200 */
  --mod-ff-200: #ee8625;
  /* Module Colors/Feature Flags/100 */
  --mod-ff-100: #fcf4e3;
  /* Module Colors/Change Intelligence/300 */
  --mod-ch-intel-300: #6938c0;
  /* Module Colors/Change Intelligence/200 */
  --mod-ch-intel-200: #ae82fc;
  /* Module Colors/Change Intelligence/100 */
  --mod-ch-intel-100: #f6f1ff;
  --mod-srm-100: #f6f1ff;
  --mod-srm-200: #ae82fc;
  --mod-srm-300: #6938c0;
  --mod-sto-100: #ebefff;
  --mod-sto-200: #4791ff;
  --mod-sto-300: #2e1cf3;
  --mod-ce-100: #fff1f7;
  --mod-ce-200: #ff006a;
  --mod-ce-300: #9a0041;
  --cc-theme-bg: var(--mod-cc-100);
  --ff-theme-bg: var(--mod-ff-100);
  --srm-theme-bg: var(--mod-srm-100);
  --sto-theme-bg: var(--mod-sto-100);
  --ce-theme-bg: var(--mod-ce-100);
  /* Linear Gradients */
  --orange-gradient: linear-gradient(135deg #f80 #e63535);
  /* view width breakpoints */
  --width-desktop: 1680px; /* max width */
  --width-laptop: 1440px;
  --width-laptop-s: 1200px;
  --width-tablet: 834px;
  --width-mobile: 375px;
  /* page padding left&right for different breakpoints */
  --padding-desktop: 111px; /* > 1440 */
  --padding-laptop: 48px; /* 1200-1439 */
  --padding-tablet: 48px; /* 834-1199 */
  --padding-mobile-large: 24px; /* 376-833 */
  --padding-mobile: 16px; /* 375 */
  /* --- Elevation/Shadow --- */
  /* offset-x | offset-y | blur-radius | spread-radius | color */
  /* Light / Elevation 03 */
  --elevation-3: 0 0 2px rgb(40 41 61 / 4%), 0 4px 8px rgb(96 97 112 / 16%);
  /* Light / Elevation 04 */
  --elevation-4: 0 2px 4px rgb(40 41 61 / 4%), 0 8px 16px rgb(96 97 112 / 16%);
}

html,
body {
  width: 100%;
  height: 100%;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
  text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@viewport {
  width: device-width;
}
body {
  color: #000;
  margin: 0;
  font-size: 14px;
  font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  font-feature-settings: "tnum";
  overflow-y: hidden;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline dotted;
  -webkit-text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}

address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}

input[type=text],
input[type=password],
input[type=number],
textarea {
  appearance: none;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1em;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  text-decoration-skip: objects;
}

a:hover {
  color: #40a9ff;
}

a:active {
  color: #096dd9;
}

a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}

a:focus {
  text-decoration: none;
  outline: 0;
}

a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier, monospace;
}

pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}

figure {
  margin: 0 0 1em;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

a,
area,
button,
[role=button],
input:not([type=range]),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  appearance: none;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

mark {
  padding: 0.2em;
  background-color: #feffe6;
}

::selection {
  color: #fff;
  background: #1890ff;
}

::selection {
  color: #fff;
  background: #1890ff;
}

.clearfix::before {
  display: table;
  content: "";
}

.clearfix::after {
  display: table;
  clear: both;
  content: "";
}

/* --------- antd styles --------- */
.ant-btn {
  border-radius: 6px !important;
  border: 1px solid var(--primary-7);
  color: var(--primary-7);
  font-weight: 500 !important;
}

.ant-btn-dangerous {
  color: #ff4d4f;
  border-color: #ff4d4f;
  background: #fff;
}

.ant-input,
.ant-input-number,
.ant-select,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px;
}

.ant-btn-primary {
  background: var(--primary-7);
  border-color: var(--primary-7);
  color: var(--white);
}
.ant-btn-primary:hover {
  background: var(--primary-6);
  border-color: var(--primary-6);
}

.ant-modal-content {
  border-radius: 8px;
}
.ant-modal-content .ant-modal-header {
  border-radius: 8px 8px 0 0;
}

.getStartedModal .ant-btn {
  border: none;
}
.getStartedModal .ant-modal-body {
  padding: 0;
}
.getStartedModal .ant-modal-footer {
  display: none;
}
.getStartedModal .ant-modal-close-x {
  display: none;
}
.getStartedModal .ant-carousel .slick-dots-bottom {
  bottom: 24px;
  left: 34%;
}

.ant-input-number {
  width: 100%;
}

/* --------- app styles --------- */
.roundedBox {
  border-radius: 6px;
  border: 1px solid #e5e5e5;
  width: 367px;
  height: 51px;
  padding: 10px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  background-color: #fff;
}

.pageWrapper {
  display: grid;
  min-height: 100vh;
  height: 100vh;
  position: relative;
  grid-template-columns: 84px 1fr;
}

.main {
  flex: 1;
  box-sizing: border-box;
  overflow-y: auto;
  background: var(--primary-bg) url("./assets/main-page-bg.svg") no-repeat right bottom;
  padding-bottom: 40px;
}

.pageTop h1 {
  font-size: 24px;
  line-height: 32px;
  color: var(--gray-800);
  font-weight: 600;
  padding: 36px 48px 10px;
  margin: 0;
}
.pageTop h1 + * {
  padding: 2px 48px 18px;
}
.pageTop .pageHeading {
  padding: 36px 48px 10px;
  display: flex;
  place-content: center space-between;
}
.pageTop .pageHeading > h1 {
  padding: 0;
}
.pageTop .pageHeading > h1 + * {
  padding: 0;
}
.pageTop .pageHeading + * {
  padding: 2px 48px 18px;
}
.pageTop p {
  font-size: 16px;
  color: #4f5162;
  margin: 0;
}

ul,
li {
  margin: 0;
  padding: 0;
}

h2 {
  color: var(--gray-800);
  font-weight: 600;
  font-size: 28px;
  margin: 0;
}

.selectStyle {
  border-color: #d9dae5;
}

.tooptip {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.tooptip svg path {
  fill: #000;
}
.tooptip:hover svg path {
  fill: #0063f7;
}

.btns {
  position: fixed;
  left: 85px;
  bottom: 0;
  width: 100%;
  padding: 20px;
  background: #fff;
  padding-left: 64px;
  box-shadow: 0 2px 6px rgba(96, 97, 112, 0.16);
}
.btns button {
  width: 133px;
  min-width: fit-content;
  height: 38px;
  justify-content: center;
  margin-left: 20px;
}
.btns button:first-child {
  margin-left: 0;
}
.btns .skipBtn {
  margin-left: 64px;
}

.errorForm .roundedBox {
  border-color: #dd2b0e;
}
.errorForm .erroInfo {
  color: #dd2b0e;
  font-size: 12px;
  padding: 3px 0 0;
}

.alignCenter {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.alignCenter img {
  width: 80px;
}

.step3FormWrapper .formSection .itemRight .rangeBox,
.step3FormWrapper .formSection .itemRight .buttonBox {
  display: none;
}
.step3FormWrapper .formSection .itemRight .inputBox {
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.step3FormWrapper .formSection .itemRight .inputBox .roundedBox {
  height: 42px;
  width: 205px !important;
}

.resultSection .ant-list-header {
  border-bottom: none !important;
}
.resultSection .resultScroll {
  top: 80px;
}

.disabled {
  pointer-events: none;
  filter: grayscale(1) opacity(60%);
}

#root {
  min-width: 1200px;
  overflow-y: auto;
}

iframe {
  border: none;
}