._step1form_1fxrv_1 {
  max-width: 1000px;
  margin: 65px auto 0 64px;
  padding: 0;
  box-sizing: border-box;
}
._step1form_1fxrv_1 p {
  padding-bottom: 75px;
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  color: #004ba4;
}
._step1form_1fxrv_1 ul {
  padding-bottom: 100px;
}
._step1form_1fxrv_1 ul li {
  list-style: none;
  padding-bottom: 35px;
}
._step1form_1fxrv_1 ul li label {
  font-size: 14px;
  color: #404040;
  display: block;
  padding-bottom: 10px;
}
._step1form_1fxrv_1 ul ._dropdownBox_1fxrv_27 {
  width: 367px;
}
._step1form_1fxrv_1 ._btns_1fxrv_30 button {
  margin-top: 40px;
  width: 133px;
  height: 38px;
  justify-content: center;
}
._step1form_1fxrv_1 ._btns_1fxrv_30 button:first-child {
  margin-right: 20px;
}

._step2form_1fxrv_40 {
  padding: 32px;
  margin: 0 auto;
}
._step2form_1fxrv_40 p {
  padding-top: 65px;
  padding-bottom: 46px;
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  color: #004ba4;
}
._step2form_1fxrv_40 ._selectModules_1fxrv_52 {
  display: flex;
  flex-flow: row nowrap;
  box-shadow: 2px 3px 6px #bcbcbc;
  background-color: #fff;
  border-radius: 4px;
}
._step2form_1fxrv_40 ._selectModules_1fxrv_52 ._illustration_1fxrv_59 {
  flex-grow: 1;
  flex-shrink: 1;
  padding: 24px;
}
._step2form_1fxrv_40 ._selectModules_1fxrv_52 ._illustration_1fxrv_59 ._imgIlluModule_1fxrv_64 {
  width: 100%;
  height: auto;
}
._step2form_1fxrv_40 ._selectModules_1fxrv_52 ._illustration_1fxrv_59 h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #000;
  padding: 24px 24px 0;
}
._step2form_1fxrv_40 ._selectModules_1fxrv_52 ._illustration_1fxrv_59 h4 {
  font-family: "Reenie Beanie";
  font-weight: 500;
  font-size: 30px;
  line-height: 30px;
  color: #0278d5;
  padding: 8px 24px;
}
._step2form_1fxrv_40 ._selectModules_1fxrv_52 ._illustration_1fxrv_59 ._illuDesc_1fxrv_83 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4f5162;
  padding: 0 24px;
}
._step2form_1fxrv_40 ul {
  flex: 0 0 390px;
  display: flex;
  place-content: flex-start center;
  padding: 10px 0;
  margin-bottom: 40px;
  flex-flow: row wrap;
  background-color: var(--primary-1);
}
._step2form_1fxrv_40 ul li {
  width: 150px;
  height: 150px;
  overflow: hidden;
  list-style: none;
  box-shadow: 2px 2px 10px #bcbcbc;
  border-radius: 10px;
  text-align: center;
  box-sizing: border-box;
  padding: 24px 0 0;
  position: relative;
  border: 2px solid transparent;
  cursor: pointer;
  background: #fff;
  margin: 16px;
}
._step2form_1fxrv_40 ul li > div svg, ._step2form_1fxrv_40 ul li > div svg {
  width: 50px;
  height: auto;
}
._step2form_1fxrv_40 ul li h4 {
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 6px 12px;
  font-weight: 500;
  line-height: 18px;
}
._step2form_1fxrv_40 ul li:hover {
  border-width: 2px;
  border-style: solid;
}
._step2form_1fxrv_40 ul li span {
  position: absolute;
  right: 0;
  top: 0;
  display: none;
  width: 55px;
  height: 55px;
}
._step2form_1fxrv_40 ul li span i {
  width: 0;
  height: 0;
  border-left: 55px solid transparent;
  position: absolute;
  top: 0;
  right: 0;
}
._step2form_1fxrv_40 ul li span ._icon_1fxrv_147 {
  position: absolute;
  top: 8px;
  right: 8px;
}
._step2form_1fxrv_40 ul li span ._icon_1fxrv_147 svg,
._step2form_1fxrv_40 ul li span ._icon_1fxrv_147 img {
  width: 18px;
  height: auto;
}
._step2form_1fxrv_40 ul li:nth-child(2) i {
  border-top: 55px solid #6ec06c;
}
._step2form_1fxrv_40 ul li:nth-child(2):hover {
  border: 2px solid #6ec06c;
}
._step2form_1fxrv_40 ul li:nth-child(2)._selected_1fxrv_163 {
  border: 2px solid #6ec06c;
}
._step2form_1fxrv_40 ul li:nth-child(2)._selected_1fxrv_163 span {
  display: block;
}
._step2form_1fxrv_40 ul li:nth-child(1) i {
  border-top: 55px solid #19a5f4;
}
._step2form_1fxrv_40 ul li:nth-child(1):hover {
  border: 2px solid #19a5f4;
}
._step2form_1fxrv_40 ul li:nth-child(1)._selected_1fxrv_163 {
  border: 2px solid #19a5f4;
}
._step2form_1fxrv_40 ul li:nth-child(1)._selected_1fxrv_163 span {
  display: block;
}
._step2form_1fxrv_40 ul li:nth-child(3) i {
  border-top: 55px solid #e76f34;
}
._step2form_1fxrv_40 ul li:nth-child(3):hover {
  border: 2px solid #e76f34;
}
._step2form_1fxrv_40 ul li:nth-child(3)._selected_1fxrv_163 {
  border: 2px solid #e76f34;
}
._step2form_1fxrv_40 ul li:nth-child(3)._selected_1fxrv_163 span {
  display: block;
}
._step2form_1fxrv_40 ul li:nth-child(4) i {
  border-top: 55px solid #01c9cc;
}
._step2form_1fxrv_40 ul li:nth-child(4):hover {
  border: 2px solid #01c9cc;
}
._step2form_1fxrv_40 ul li:nth-child(4)._selected_1fxrv_163 {
  border: 2px solid #01c9cc;
}
._step2form_1fxrv_40 ul li:nth-child(4)._selected_1fxrv_163 span {
  display: block;
}
._step2form_1fxrv_40 ul li:nth-child(5) i {
  border-top: 55px solid #ae82fc;
}
._step2form_1fxrv_40 ul li:nth-child(5):hover {
  border: 2px solid #ae82fc;
}
._step2form_1fxrv_40 ul li:nth-child(5)._selected_1fxrv_163 {
  border: 2px solid #ae82fc;
}
._step2form_1fxrv_40 ul li:nth-child(5)._selected_1fxrv_163 span {
  display: block;
}
._step2form_1fxrv_40 ul li:nth-child(6) i {
  border-top: 55px solid #4791ff;
}
._step2form_1fxrv_40 ul li:nth-child(6):hover {
  border: 2px solid #4791ff;
}
._step2form_1fxrv_40 ul li:nth-child(6)._selected_1fxrv_163 {
  border: 2px solid #4791ff;
}
._step2form_1fxrv_40 ul li:nth-child(6)._selected_1fxrv_163 span {
  display: block;
}
._step2form_1fxrv_40 ul li:nth-child(7) i {
  border-top: 55px solid #ff006a;
}
._step2form_1fxrv_40 ul li:nth-child(7):hover {
  border: 2px solid #ff006a;
}
._step2form_1fxrv_40 ul li:nth-child(7)._selected_1fxrv_163 {
  border: 2px solid #ff006a;
}
._step2form_1fxrv_40 ul li:nth-child(7)._selected_1fxrv_163 span {
  display: block;
}
._step2form_1fxrv_40 ._btns_1fxrv_30 button {
  width: 133px;
  height: 38px;
  justify-content: center;
}
._step2form_1fxrv_40 ._btns_1fxrv_30 button:first-child {
  margin-right: 20px;
}

._step3form_1fxrv_250 ._step3formInner_1fxrv_250 {
  margin: 65px 320px calc(100vh - 300px) 64px;
}
._step3form_1fxrv_250 p {
  padding-bottom: 55px;
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  color: #004ba4;
}
._step3form_1fxrv_250 .pannel {
  min-width: 732px;
}

._step5form_1fxrv_264 {
  padding: 0 48px 24px;
}
._step5form_1fxrv_264 p {
  padding-bottom: 55px;
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  color: #004ba4;
}

._step6form_1fxrv_275 {
  padding: 40px 0 0 255px;
  display: flex;
  justify-content: left;
  align-items: center;
}
._step6form_1fxrv_275 ._step6formLeft_1fxrv_281 {
  width: 450px;
}
._step6form_1fxrv_275 ._step6formLeft_1fxrv_281 h2 {
  font-size: 28px;
  color: #000;
  line-height: 32px;
  font-weight: normal;
  padding: 0 0 21px;
}
._step6form_1fxrv_275 ._step6formLeft_1fxrv_281 p {
  font-size: 16px;
  margin: 0;
  padding: 0 0 38px;
}
._step6form_1fxrv_275 ._step6formLeft_1fxrv_281 ul {
  padding: 0 0 42px;
}
._step6form_1fxrv_275 ._step6formLeft_1fxrv_281 ul li {
  list-style: none;
  font-size: 16px;
  color: #22222a;
  padding: 0 0 16px;
}
._step6form_1fxrv_275 ._step6formLeft_1fxrv_281 ._btns_1fxrv_30 a {
  text-decoration: none;
}
._step6form_1fxrv_275 ._step6formLeft_1fxrv_281 ._btns_1fxrv_30 button {
  display: block;
  margin: 0 0 18px;
}
._step6form_1fxrv_275 ._step6formLeft_1fxrv_281 ._btns_1fxrv_30 img {
  margin-left: 0.35em;
}

._warning_1fxrv_316 {
  color: var(--red-700);
  margin-right: 0.25em;
}

@keyframes _breathe_1fxrv_1 {
  0% {
    font-size: 12px;
    color: #000;
  }
  50% {
    font-size: 13px;
    color: #666;
  }
  100% {
    font-size: 12px;
    color: #000;
  }
}
._loading_1fxrv_335 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}
._loading_1fxrv_335 p {
  text-align: center;
  animation-name: _breathe_1fxrv_1;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
}

._loadingDiv_1fxrv_349 {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}