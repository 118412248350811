._inputTime_6dus7_1 {
  display: flex;
}
._inputTime_6dus7_1 ._number_6dus7_4 {
  max-width: 152px;
  flex-shrink: 1;
}
._inputTime_6dus7_1 ._timeUnit_6dus7_8 {
  width: 100px;
  margin: 0 8px;
}