._formulaLabelFont_eb37j_1,
._improvementLabelFont_eb37j_2 {
  color: var(--Light-Text-Tertiary, var(--Gray-Scale-Gray-Scale-500, #6b6d85));
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

._formulaContentFont_eb37j_12,
._improvementContentFont_eb37j_13 {
  color: var(--Light-Text-Tertiary, var(--Gray-Scale-Gray-Scale-500, #6b6d85));
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

._quoteDiv_eb37j_23 {
  display: flex;
  padding: 8px 8px 8px 0;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

._quoteLine_eb37j_31 {
  width: 1px;
  height: 100%;
  background: #6b6d85;
}

._quoteFont_eb37j_37 {
  color: var(--Light-Text-Tertiary, var(--Gray-Scale-Gray-Scale-500, #6b6d85));
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

._saveAsDraftBtn_eb37j_47 {
  border: transparent;
  margin-left: 0 !important;
}