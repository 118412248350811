.comingSoon {
  margin-bottom: 50px;
}

.formSectionOuter {
  padding: 36px 40px;
  box-shadow: #e5e5e5 1px 1px 10px;
  background-color: #fff;
}
.formSectionOuter p {
  padding-bottom: 0;
}
.formSectionOuter .site-collapse {
  background-color: white;
}
.formSectionOuter .site-collapse .ant-collapse-header {
  width: fit-content;
  margin-bottom: 0;
  font-weight: 500;
}
.formSectionOuter .site-collapse .ant-collapse-arrow {
  right: 0 !important;
  color: #0278d5;
}
.formSectionOuter .site-collapse .ant-collapse-header-text {
  font-weight: bold;
}
.formSectionOuter .note-collapse {
  font-size: 13px;
  line-height: 16px;
  background: transparent;
}
.formSectionOuter .note-collapse .ant-collapse-header {
  border-radius: 4px !important;
  width: fit-content;
  background: #f3f3fa;
  padding: 4px 35px 4px 10px;
  margin-bottom: 0;
}
.formSectionOuter .note-collapse .ant-collapse-header:hover {
  background: #fafbfc;
  filter: drop-shadow(0 0 1px rgba(40, 41, 61, 0.04)) drop-shadow(0 2px 4px rgba(96, 97, 112, 0.16));
}
.formSectionOuter .note-collapse .ant-collapse-header:focus {
  background: #f3f3fa;
  box-shadow: inset 0 0.5px 4px rgba(96, 97, 112, 0.32);
}
.formSectionOuter .note-collapse .ant-collapse-arrow {
  right: 13px !important;
  color: #22222a;
}
.formSectionOuter .note-collapse .ant-collapse-content-box {
  padding-left: 0;
  padding-right: 0;
}
.formSectionOuter .note-collapse .ant-collapse-content-box .ant-form-item:last-child {
  margin-bottom: 0;
}
.formSectionOuter .note-collapse .ant-form-item p {
  padding: 15px 0 5px;
  color: #22222a;
}
.formSectionOuter .note-collapse .ant-form-item p span {
  padding-left: 5px;
  color: #4f5162;
}
.formSectionOuter .note-collapse.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: none;
}
.formSectionOuter .formSection {
  padding: 40px 0;
  border-bottom: 1px solid #d9dae5;
}
.formSectionOuter .formSection .title {
  display: flex;
  align-items: center;
}
.formSectionOuter .formSection .title h5 {
  margin: 0 20px 0 0;
  padding: 0 0 20px;
  font-size: 16px;
  font-weight: normal;
  color: #0b0b0d;
}
.formSectionOuter .formSection .title .icon {
  padding-right: 12px;
  margin-top: -10px;
}
.formSectionOuter .formSection .title .icon svg {
  width: 30px;
  height: 30px;
}
.formSectionOuter .formSection .title .tooptip {
  margin-top: -18px;
}
.formSectionOuter .formSection .formSectionInner {
  margin-bottom: 12px;
  display: flex;
  flex-flow: row nowrap;
}
.formSectionOuter .formSection .formSectionInner > .formItems {
  flex-grow: 1;
  padding: 24px;
  border-radius: 4px;
  background: var(--Text-selected, #effbff);
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 50vw;
}
.formSectionOuter .formSection .formSectionInner > .previewItems {
  flex-basis: 350px;
  padding: 0 0 0 20px;
  margin-left: 20px;
  border-left: 1px solid #ccc;
}
.formSectionOuter .formSection .formSectionInner > div {
  margin: 16px 0 8px;
}
.formSectionOuter .formSection .formSectionInner .row {
  display: flex;
  justify-content: space-between;
  padding: 0 0 20px;
}
.formSectionOuter .formSection .formSectionInner .row textarea {
  box-shadow: #e5e5e5 1px 1px 10px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  width: 100%;
  min-height: 78px;
  resize: none;
}
.formSectionOuter .formSection .formSectionInner .row .icons {
  display: flex;
  align-items: center;
  padding: 15px 0 0;
  font-size: 14px;
  color: #525162;
}
.formSectionOuter .formSection .formSectionInner .row .icons span {
  height: 33px;
  justify-content: center;
  align-items: center;
  box-shadow: #e5e5e5 1px 1px 10px;
  margin: 0 20px 0 0;
  white-space: nowrap;
  padding: 0 10px;
  display: inline-flex;
  font-size: 12px;
}
.formSectionOuter .formSection .formSectionInner .row .icons span:nth-child(1) {
  margin-left: 30px;
}
.formSectionOuter .formSection .formSectionInner .row .icons span img {
  vertical-align: middle;
  background-color: #aaa;
  padding: 3px;
  border-radius: 5px;
  margin: 0 4px 0 0;
}
.formSectionOuter .formSection .formSectionInner .row .itemRight {
  display: flex;
}
.formSectionOuter .formSection .formSectionInner .row .itemRight.displayBlock {
  display: block;
}
.formSectionOuter .formSection .formSectionInner .row.edit .itemLeft {
  width: 423px;
  flex: 0 0 auto;
}
.formSectionOuter .formSection .formSectionInner .row.edit .itemRight {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
}
.formSectionOuter .formSection .formSectionInner .row.edit .itemRight .value {
  min-width: 135px;
  padding: 0 10px 0 0;
}
.formSectionOuter .formSection .formSectionInner .row.edit .itemRight .value .valueItem {
  display: inline-flex;
  font-size: 12px;
  color: #4f5162;
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
  border-radius: 7px;
  padding: 5px;
  align-items: center;
  background: #fff;
  margin: 0 24px 0 0;
}
.formSectionOuter .formSection .formSectionInner .row.edit .itemRight .value .valueItem img.icon {
  margin: 0 4px 0 0;
  background-color: #cdf4fe;
  border-radius: 5px;
  padding: 2px;
}
.formSectionOuter .formSection .formSectionInner .row.edit .itemRight .value .valueItem .iconError {
  margin: 0 0 0 10px;
}
.formSectionOuter .formSection .formSectionInner .row.edit .itemRight .formBox {
  display: flex;
}
.formSectionOuter .formSection .formSectionInner .row.edit .itemRight .inputBox {
  display: flex;
  align-items: center;
}
.formSectionOuter .formSection .formSectionInner .row.edit .itemRight .inputBox input {
  text-align: center;
}
.formSectionOuter .formSection .formSectionInner .row.edit .itemRight .inputBox span {
  padding: 0 0 0 10px;
}
.formSectionOuter .formSection .formSectionInner .row.edit .itemRight .icon svg {
  cursor: pointer;
}
.formSectionOuter .formSection .formSectionInner .row.edit .itemRight .firstBtn {
  margin: 0 12px 0 43px;
}
.formSectionOuter .formSection .formSectionInner .row.edit .itemRight .minWidth {
  min-width: 300px;
}
.formSectionOuter .formSection .formSectionInner .col1 {
  width: 100%;
  display: block;
}
.formSectionOuter .formSection .formSectionInner .rangeItem {
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
}
.formSectionOuter .formSection .formSectionInner .rangeItem label {
  width: 400px;
  font-size: 12px;
  display: inline-block;
  padding-left: 50px;
}
.formSectionOuter .formSection .formSectionInner .rangeItem .inputBox {
  width: 100px;
  display: flex;
  align-items: center;
}
.formSectionOuter .formSection .formSectionInner .rangeItem .inputBox span {
  margin: 0 0 0 10px;
}
.formSectionOuter .formSection .formSectionInner .rangeItem .inputBox input {
  text-align: center;
}
.formSectionOuter .formSection .formSectionInner .rangeItem .buttonBox {
  margin-left: 50px;
  cursor: pointer;
}
.formSectionOuter .formSection .formSectionInner .flexLine {
  display: flex;
}
.formSectionOuter .formSection .formSectionInner .selectWrapper {
  width: 115px;
  padding: 0 0 0 10px;
}
.formSectionOuter .formSection .formSectionInner label {
  display: flex;
  align-items: center;
  color: var(--Gray-Scale-Black, #000);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
}
.formSectionOuter > div:last-child .ant-collapse-borderless > .ant-collapse-item {
  border-bottom: none;
}

.step5FormWrapper .formSection .formSectionInner {
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
  background: none;
}