._menu_um6ad_1 {
  display: flex;
  padding: 0 0 0 36px;
}
._menu_um6ad_1 li {
  list-style: none;
  display: flex;
  height: 49px;
  margin: 0 2px;
  padding: 0 1em;
  align-items: center;
  justify-content: center;
  border-radius: 5px 5px 0 0;
  border-color: var(--white);
  cursor: pointer;
}
._menu_um6ad_1 li h4 {
  display: none;
}
._menu_um6ad_1 li._common_um6ad_20 {
  background-color: var(--white);
}
._menu_um6ad_1 li._cd_um6ad_23 {
  background-color: #f6fff2;
}
._menu_um6ad_1 li._ci_um6ad_26 {
  background-color: #effbff;
}
._menu_um6ad_1 li._ff_um6ad_29 {
  background-color: #fff5ed;
}
._menu_um6ad_1 li._cc_um6ad_32 {
  background-color: #ecffff;
}
._menu_um6ad_1 li._srm_um6ad_35 {
  background-color: #f6f1ff;
}
._menu_um6ad_1 li._sto_um6ad_38 {
  background-color: #ebefff;
}
._menu_um6ad_1 li._ce_um6ad_41 {
  background-color: #fff1f7;
}
._menu_um6ad_1 li._active_um6ad_44 {
  border-style: solid;
  border-width: 1px;
  border-bottom: none;
  background-color: var(--white);
  cursor: default;
}
._menu_um6ad_1 li._active_um6ad_44 h4 {
  display: block;
}
._menu_um6ad_1 li._active_um6ad_44._common_um6ad_20 {
  border-color: var(--primary-7);
}
._menu_um6ad_1 li._active_um6ad_44._cd_um6ad_23 {
  border-color: #6ec06c;
}
._menu_um6ad_1 li._active_um6ad_44._ci_um6ad_26 {
  border-color: #19a5f4;
}
._menu_um6ad_1 li._active_um6ad_44._ff_um6ad_29 {
  border-color: #e76f34;
}
._menu_um6ad_1 li._active_um6ad_44._cc_um6ad_32 {
  border-color: #01c9cc;
}
._menu_um6ad_1 li._active_um6ad_44._srm_um6ad_35 {
  border-color: #ae82fc;
}
._menu_um6ad_1 li._active_um6ad_44._sto_um6ad_38 {
  border-color: #ae82fc;
}
._menu_um6ad_1 li._active_um6ad_44._ce_um6ad_41 {
  border-color: #ae82fc;
}
._menu_um6ad_1 li h4 {
  font-size: 14px;
  padding: 0 0 0 6px;
  line-height: 110%;
}
._menu_um6ad_1 li svg {
  width: 34px;
  height: 34px;
}

._sticky_um6ad_88 {
  position: fixed;
  top: 0;
  width: 100%;
  left: 85px;
  z-index: 99;
  box-shadow: 0 2px 6px rgba(96, 97, 112, 0.16);
  background: #fff;
  padding: 10px 10px 2px 100px;
}

._afterSticky_um6ad_99 {
  margin-top: 146px;
}