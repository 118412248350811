._leftNav_ck4y6_1 {
  width: 85px;
  box-sizing: border-box;
  border-right: #ebebeb 1px solid;
  padding-top: 28px;
  background: #07182b url("assets/menu_bg.svg") right bottom no-repeat;
  position: relative;
  color: #fff;
}
._leftNav_ck4y6_1 ._logo_ck4y6_10 {
  display: flex;
  justify-content: center;
}
._leftNav_ck4y6_1 menu {
  padding: 94px 0 0;
  margin: 0;
  display: flex;
  justify-content: center;
}
._leftNav_ck4y6_1 menu * {
  box-sizing: content-box;
}
._leftNav_ck4y6_1 menu li {
  list-style: none;
  padding: 0 0 45px;
}
._leftNav_ck4y6_1 menu li a {
  width: 48px;
  padding: 10px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  color: #fff;
}
._leftNav_ck4y6_1 menu li a div {
  display: flex;
  justify-content: center;
}
._leftNav_ck4y6_1 menu li a svg path {
  stroke: #c7c7c7;
}
._leftNav_ck4y6_1 menu li a:hover {
  background-color: #0278d5;
  color: #fff;
}
._leftNav_ck4y6_1 menu li a:hover svg path {
  stroke: #fff;
}
._leftNav_ck4y6_1 menu li ._menuItem_ck4y6_50 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
._leftNav_ck4y6_1 menu li ._menuItem_ck4y6_50 ._menuName_ck4y6_56 {
  font-size: 11px;
  margin-top: 4px;
}
._leftNav_ck4y6_1 menu li._selected_ck4y6_60 a {
  background-color: #0278d5;
  color: #fff;
}
._leftNav_ck4y6_1 menu li._selected_ck4y6_60 a svg path {
  stroke: #fff;
}
._leftNav_ck4y6_1 menu ._signOut_ck4y6_67 {
  cursor: pointer;
  padding: 64px 0 0;
}
._leftNav_ck4y6_1 ._avatar_ck4y6_71 {
  position: absolute;
  border-radius: 50%;
  bottom: 80px;
  left: 20px;
  width: 42px;
  height: 42px;
}
._leftNav_ck4y6_1 ._avatar_ck4y6_71 img {
  width: 100%;
  height: 100%;
}
._leftNav_ck4y6_1 ._avatar_ck4y6_71 ._initials_ck4y6_83 {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 2px solid var(--primary-1);
  background: var(--primary-bg);
  color: var(--primary-8);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  font-family: Inter;
  cursor: default;
}
._leftNav_ck4y6_1 ._avatar_ck4y6_71 ._signOut_ck4y6_67 {
  cursor: pointer;
  font-size: 11px;
  line-height: 12px;
  color: var(--white);
  margin-top: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
._leftNav_ck4y6_1 ._avatar_ck4y6_71 ._signOut_ck4y6_67:hover {
  color: var(--primary-4);
}