body {
  overflow-y: auto;
}

._main_1ysgk_5 ._hero_1ysgk_5 {
  padding: 38px 0;
  min-height: 628px;
  background: url("./assets/hero.svg") no-repeat top right;
  background-size: 60%;
}
._main_1ysgk_5 ._hero_1ysgk_5 ._inner_1ysgk_11 {
  padding-left: 100px;
  width: 45%;
}
._main_1ysgk_5 ._hero_1ysgk_5 ._inner_1ysgk_11 h2 {
  margin-top: 138px;
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
}
._main_1ysgk_5 ._hero_1ysgk_5 ._inner_1ysgk_11 h4 {
  font-family: "Reenie Beanie";
  font-weight: 500;
  font-size: 30px;
  line-height: 24px;
  margin-top: 20px;
  color: var(--primary-7);
}
._main_1ysgk_5 ._hero_1ysgk_5 ._inner_1ysgk_11 ._desc_1ysgk_29 {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  margin: 20px 0 48px;
}
._main_1ysgk_5 ._features_1ysgk_35 {
  min-height: 628px;
  padding: 70px 0 40px;
  text-align: center;
}
._main_1ysgk_5 ._features_1ysgk_35 h2 {
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
}
._main_1ysgk_5 ._features_1ysgk_35 ._desc_1ysgk_29 {
  margin-top: 8px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
._main_1ysgk_5 ._features_1ysgk_35 ._imgWrapper_1ysgk_53 {
  text-align: center;
  padding-top: 12px;
}
._main_1ysgk_5 ._features_1ysgk_35 ._imgWrapper_1ysgk_53 img {
  width: 1200px;
  height: auto;
  max-width: 90%;
}
._main_1ysgk_5 ._features_1ysgk_35 ._btnWrapper_1ysgk_62 {
  padding: 64px 0;
}
._main_1ysgk_5 ._divider_1ysgk_65 {
  min-height: 210px;
}
._main_1ysgk_5 ._footer_1ysgk_68 {
  background: var(--primary-10);
  padding: 36px 0 24px;
}
._main_1ysgk_5 ._footer_1ysgk_68 ._logoIcons_1ysgk_72 {
  width: 100%;
  max-width: var(--max-width);
  padding: 0 var(--page-padding);
  box-sizing: border-box;
  margin: 24px auto 0;
  display: grid;
  grid-template-columns: 180px 1fr;
}
._main_1ysgk_5 ._footer_1ysgk_68 ._logoIcons_1ysgk_72 ._followUs_1ysgk_81 {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: var(--gray-400);
}
._main_1ysgk_5 ._footer_1ysgk_68 ._logoIcons_1ysgk_72 ._followUs_1ysgk_81 a {
  color: var(--white);
}
._main_1ysgk_5 ._footer_1ysgk_68 ._logoIcons_1ysgk_72 ._followUs_1ysgk_81 a:hover, ._main_1ysgk_5 ._footer_1ysgk_68 ._logoIcons_1ysgk_72 ._followUs_1ysgk_81 a:active {
  color: var(--primary-7);
}
._main_1ysgk_5 ._footer_1ysgk_68 ._logoIcons_1ysgk_72 ._followUs_1ysgk_81 li {
  list-style: none;
  color: var(--white);
  margin-left: 18px;
}
._main_1ysgk_5 ._footer_1ysgk_68 ._logoIcons_1ysgk_72 ._followUs_1ysgk_81 li svg {
  width: 32px;
  height: 32px;
}
._main_1ysgk_5 ._footer_1ysgk_68 ._termsNPolicies_1ysgk_106 {
  width: 100%;
  max-width: var(--max-width);
  padding: 24px var(--page-padding);
  box-sizing: border-box;
  margin: 28px auto 0;
  display: grid;
  grid-template-columns: 1fr 200px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  text-transform: capitalize;
  color: var(--gray-600);
}
._main_1ysgk_5 ._footer_1ysgk_68 ._termsNPolicies_1ysgk_106 ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row nowrap;
}
._main_1ysgk_5 ._footer_1ysgk_68 ._termsNPolicies_1ysgk_106 ul li {
  list-style: none;
  margin-right: 56px;
}
._main_1ysgk_5 ._footer_1ysgk_68 ._termsNPolicies_1ysgk_106 ul li a {
  color: var(--gray-600);
}
._main_1ysgk_5 ._footer_1ysgk_68 ._termsNPolicies_1ysgk_106 ul li a:hover, ._main_1ysgk_5 ._footer_1ysgk_68 ._termsNPolicies_1ysgk_106 ul li a:active {
  color: var(--primary-7);
}
._main_1ysgk_5 ._footer_1ysgk_68 ._termsNPolicies_1ysgk_106 ._copyRight_1ysgk_137 {
  text-align: right;
}
._main_1ysgk_5 ._bgDotBlue_1ysgk_140 {
  background: linear-gradient(90deg, #07182b 9px, transparent 1%) center, linear-gradient(#07182b 9px, transparent 1%) center, var(--gray-500);
  background-size: 10px 10px;
  box-shadow: inset 0 6px 25px rgba(133, 96, 96, 0.04);
}

._responsive_1ysgk_146 {
  margin: 0 48px;
}