._searchBar_3fkd6_1 {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 56px;
  background: #fff;
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.08), 0 0.5px 2px rgba(96, 97, 112, 0.16);
  padding: 0 48px;
}
._searchBar_3fkd6_1 ._searchBarLeft_3fkd6_11 {
  padding: 0 20px 0 0;
  width: 125px;
  box-sizing: border-box;
  flex: 0 0 auto;
}
._searchBar_3fkd6_1 ._searchBarLeft_3fkd6_11 button {
  font-size: 13px;
  white-space: nowrap;
}
._searchBar_3fkd6_1 ._searchBarMid_3fkd6_21 {
  padding: 0 20px 0 0;
  border: 0;
  flex: 1 1 auto;
  box-sizing: border-box;
}
._searchBar_3fkd6_1 ._searchRight_3fkd6_27 {
  display: flex;
  justify-content: right;
  align-items: center;
  flex: 0 0 auto;
  height: 51px;
}
._searchBar_3fkd6_1 ._searchRight_3fkd6_27 ._searchBtn_3fkd6_34 {
  padding: 7px;
  background-color: #f3f3fa;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 5px;
}
._searchBar_3fkd6_1 ._searchRight_3fkd6_27 ._selectBox_3fkd6_44 {
  width: 150px;
  height: 32px;
  margin: 0 0 0 22px;
  flex: 0 0 auto;
}
._searchBar_3fkd6_1 ._searchRight_3fkd6_27 > span {
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin: 0;
  cursor: pointer;
}
._searchBar_3fkd6_1 ._searchRight_3fkd6_27 > span._view_3fkd6_59 > a, ._searchBar_3fkd6_1 ._searchRight_3fkd6_27 > span._export_3fkd6_59 > a {
  font-size: 24px;
  color: var(--gray-800);
  width: 51px;
  text-align: right;
  justify-content: flex-end;
}
._searchBar_3fkd6_1 ._searchRight_3fkd6_27 > span._view_3fkd6_59 > a svg rect, ._searchBar_3fkd6_1 ._searchRight_3fkd6_27 > span._export_3fkd6_59 > a svg rect {
  fill: var(--gray-800);
}
._searchBar_3fkd6_1 ._searchRight_3fkd6_27 > span._view_3fkd6_59 > a svg path, ._searchBar_3fkd6_1 ._searchRight_3fkd6_27 > span._export_3fkd6_59 > a svg path {
  fill: var(--gray-800);
}
._searchBar_3fkd6_1 ._searchRight_3fkd6_27 > span._view_3fkd6_59 > a._selected_3fkd6_72, ._searchBar_3fkd6_1 ._searchRight_3fkd6_27 > span._export_3fkd6_59 > a._selected_3fkd6_72 {
  color: var(--primary-7);
}
._searchBar_3fkd6_1 ._searchRight_3fkd6_27 > span._view_3fkd6_59 > a._selected_3fkd6_72 svg rect, ._searchBar_3fkd6_1 ._searchRight_3fkd6_27 > span._export_3fkd6_59 > a._selected_3fkd6_72 svg rect {
  fill: var(--primary-7);
}
._searchBar_3fkd6_1 ._searchRight_3fkd6_27 > span._view_3fkd6_59 > a._selected_3fkd6_72 svg path, ._searchBar_3fkd6_1 ._searchRight_3fkd6_27 > span._export_3fkd6_59 > a._selected_3fkd6_72 svg path {
  fill: var(--primary-7);
}
._searchBar_3fkd6_1 ._searchRight_3fkd6_27 > span._view_3fkd6_59 > a:hover, ._searchBar_3fkd6_1 ._searchRight_3fkd6_27 > span._export_3fkd6_59 > a:hover {
  color: var(--primary-7);
}
._searchBar_3fkd6_1 ._searchRight_3fkd6_27 > span._view_3fkd6_59 > a:hover svg rect, ._searchBar_3fkd6_1 ._searchRight_3fkd6_27 > span._export_3fkd6_59 > a:hover svg rect {
  fill: var(--primary-7);
}
._searchBar_3fkd6_1 ._searchRight_3fkd6_27 > span._view_3fkd6_59 > a:hover svg path, ._searchBar_3fkd6_1 ._searchRight_3fkd6_27 > span._export_3fkd6_59 > a:hover svg path {
  fill: var(--primary-7);
}

._sdcaDesc_3fkd6_91 {
  display: grid;
  padding: 2px 43px 18px;
  grid-template-columns: 1fr 350px;
}
._sdcaDesc_3fkd6_91 ._getStarted_3fkd6_96 {
  font-size: 12px;
  align-items: center;
  display: flex;
  color: var(--primary-7);
  justify-content: space-between;
}
._sdcaDesc_3fkd6_91 ._getStarted_3fkd6_96:hover {
  color: var(--primary-8);
}
._sdcaDesc_3fkd6_91 ._getStarted_3fkd6_96 span[role=img] {
  margin-right: 4px;
}

._getStartedContent_3fkd6_110 ._carouselItem_3fkd6_110 {
  display: flex;
  flex-direction: row;
  height: 588px;
}
._getStartedContent_3fkd6_110 ._carouselItem_3fkd6_110 ._left_3fkd6_115 {
  padding: 60px 35px;
  width: 32%;
  background: var(--primary-1);
  border-radius: 8px 0 0 8px;
}
._getStartedContent_3fkd6_110 ._carouselItem_3fkd6_110 ._left_3fkd6_115 h2 {
  font-family: "Reenie Beanie";
  font-weight: 500;
  font-size: 50px;
  line-height: 46px;
}
._getStartedContent_3fkd6_110 ._carouselItem_3fkd6_110 ._left_3fkd6_115 h2 span {
  color: var(--primary-8);
}
._getStartedContent_3fkd6_110 ._carouselItem_3fkd6_110 ._left_3fkd6_115 ._desc_3fkd6_130 {
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  margin: 18px 0 48px;
}
._getStartedContent_3fkd6_110 ._carouselItem_3fkd6_110 ._left_3fkd6_115 ._desc_3fkd6_130 > span {
  font-weight: 800;
}
._getStartedContent_3fkd6_110 ._carouselItem_3fkd6_110 ._left_3fkd6_115 ._thumb_3fkd6_139 {
  margin-top: 170px;
  text-align: right;
  display: flex;
  justify-content: space-between;
}
._getStartedContent_3fkd6_110 ._carouselItem_3fkd6_110 ._left_3fkd6_115 ._thumb_3fkd6_139 ._firstThumb_3fkd6_145 {
  margin-top: 66px;
}
._getStartedContent_3fkd6_110 ._carouselItem_3fkd6_110 ._left_3fkd6_115 ._thumb_3fkd6_139 ._buttons_3fkd6_148 {
  display: flex;
  align-items: flex-end;
}
._getStartedContent_3fkd6_110 ._carouselItem_3fkd6_110 ._left_3fkd6_115 ._thumb_3fkd6_139 ._buttons_3fkd6_148 ._backButton_3fkd6_152 {
  margin-left: 8px;
  background: #fff;
  border: 1px solid var(--primary-7);
  border-radius: 4px;
}
._getStartedContent_3fkd6_110 ._carouselItem_3fkd6_110 ._left_3fkd6_115 ._thumb_3fkd6_139 img {
  height: auto;
}
._getStartedContent_3fkd6_110 ._carouselItem_3fkd6_110 ._right_3fkd6_161 {
  width: 68%;
  flex-shrink: 0;
}
._getStartedContent_3fkd6_110 ._carouselItem_3fkd6_110 ._right_3fkd6_161 ._btnSkip_3fkd6_165 {
  display: flex;
  justify-content: flex-end;
  margin: 30px;
}
._getStartedContent_3fkd6_110 ._carouselItem_3fkd6_110 ._right_3fkd6_161 ._rightImg_3fkd6_170 {
  display: flex;
  justify-content: center;
}
._getStartedContent_3fkd6_110 ._carouselItem_3fkd6_110 ._right_3fkd6_161 ._rightFirst_3fkd6_174 {
  height: 100%;
  display: flex;
  align-items: flex-end;
  position: relative;
}
._getStartedContent_3fkd6_110 ._carouselItem_3fkd6_110 ._right_3fkd6_161 ._rightFirst_3fkd6_174 ._img1_3fkd6_180 {
  position: absolute;
  top: 0;
  left: 0;
}
._getStartedContent_3fkd6_110 ._carouselItem_3fkd6_110 ._right_3fkd6_161 ._rightFirst_3fkd6_174 ._img2_3fkd6_185 {
  position: absolute;
  top: 0;
  right: 0;
}
._getStartedContent_3fkd6_110 ._carouselItem_3fkd6_110 ._right_3fkd6_161 ._rightFirst_3fkd6_174 ._img2_3fkd6_185 img {
  border-radius: 0 8px 0 0;
}
._getStartedContent_3fkd6_110 ._carouselItem_3fkd6_110 ._right_3fkd6_161 ._rightFirst_3fkd6_174 ._rightDesc_3fkd6_193 {
  width: 50%;
  margin-left: 48px;
}
._getStartedContent_3fkd6_110 ._carouselItem_3fkd6_110 ._right_3fkd6_161 ._rightFirst_3fkd6_174 ._rightDesc_3fkd6_193 h1 {
  font-family: Inter;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: var(--gray-800);
}
._getStartedContent_3fkd6_110 ._carouselItem_3fkd6_110 ._right_3fkd6_161 ._rightFirst_3fkd6_174 ._rightDesc_3fkd6_193 h6 {
  font-family: "Reenie Beanie";
  font-weight: 500;
  font-size: 28px;
  line-height: 28px;
  color: var(--primary-7);
  margin-right: 50px;
}
._getStartedContent_3fkd6_110 ._carouselItem_3fkd6_110 ._right_3fkd6_161 ._rightFirst_3fkd6_174 ._rightDesc_3fkd6_193 p {
  font-family: Inter;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: var(--gray-800);
  margin-bottom: 74px;
}
._getStartedContent_3fkd6_110 ._carouselItem_3fkd6_110 ._right_3fkd6_161 img {
  max-width: 100%;
  height: auto;
}
._getStartedContent_3fkd6_110 ._dontShowAgain_3fkd6_224 {
  font-size: 12px;
}
._getStartedContent_3fkd6_110 ._dontShowAgain_3fkd6_224 .ant-checkbox-wrapper {
  font-size: 12px;
}
._getStartedContent_3fkd6_110 .slick-dots li button {
  background: var(--gray-200);
  border-radius: 50%;
  width: 8px;
  height: 8px;
}
._getStartedContent_3fkd6_110 .slick-dots li.slick-active button {
  width: 26.67px;
  height: 8px;
  left: 0;
  top: 0;
  background: var(--primary-6);
  border-radius: 20px;
}

._noPermission_3fkd6_245 {
  text-align: center;
  padding: 48px;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
._noPermission_3fkd6_245 > img {
  width: 158px;
  height: auto;
  margin-bottom: 24px;
}

._homeWrapper_3fkd6_261 {
  padding: 20px 0 0;
}
._homeWrapper_3fkd6_261 ._searchBoxWrapper_3fkd6_264 {
  display: flex;
  justify-content: space-between;
  padding: 40px 0 0;
  align-items: center;
}
._homeWrapper_3fkd6_261 ._accountLogo_3fkd6_270 {
  width: auto;
  height: auto;
  max-width: 91px;
  max-height: 26px;
}
._homeWrapper_3fkd6_261 ._tableWrapper_3fkd6_276 {
  padding: 0 48px;
}
._homeWrapper_3fkd6_261 ._tableWrapper_3fkd6_276 table {
  border-spacing: 0;
  width: 100%;
  text-align: left;
}
._homeWrapper_3fkd6_261 ._tableWrapper_3fkd6_276 table tr :last-child td {
  border-bottom: 0;
}
._homeWrapper_3fkd6_261 ._tableWrapper_3fkd6_276 table th {
  font-size: 11px;
  text-align: left;
  font-weight: 600;
  padding: 16px 24px;
}
._homeWrapper_3fkd6_261 ._tableWrapper_3fkd6_276 table th ._arrows_3fkd6_293 {
  display: inline-flex;
  flex-direction: column;
  vertical-align: middle;
}
._homeWrapper_3fkd6_261 ._tableWrapper_3fkd6_276 table th ._arrows_3fkd6_293 ._arrowTop_3fkd6_298 {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-bottom: 5px solid #000;
  margin-bottom: 2px;
  cursor: pointer;
}
._homeWrapper_3fkd6_261 ._tableWrapper_3fkd6_276 table th ._arrows_3fkd6_293 ._arrowDown_3fkd6_306 {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top: 5px solid #000;
  cursor: pointer;
}
._homeWrapper_3fkd6_261 ._tableWrapper_3fkd6_276 table td {
  padding: 5px 0;
  text-align: left;
  color: #4f5162;
}
._homeWrapper_3fkd6_261 ._tableWrapper_3fkd6_276 table td ._capitalized_3fkd6_318 {
  text-transform: capitalize;
}
._homeWrapper_3fkd6_261 ._tableWrapper_3fkd6_276 table td ._cellInner_3fkd6_321 {
  height: 65px;
  display: inline-flex;
  width: 100%;
  justify-content: left;
  align-items: center;
  font-size: 13px;
  box-sizing: border-box;
  padding: 16px 24px;
}
._homeWrapper_3fkd6_261 ._tableWrapper_3fkd6_276 table td ._cellInner_3fkd6_321 ._title_3fkd6_331 {
  font-size: 13px;
  color: #000;
}
._homeWrapper_3fkd6_261 ._tableWrapper_3fkd6_276 table td ._cellInner_3fkd6_321 ._title_3fkd6_331 ._draft_3fkd6_335,
._homeWrapper_3fkd6_261 ._tableWrapper_3fkd6_276 table td ._cellInner_3fkd6_321 ._title_3fkd6_331 ._version_3fkd6_336 {
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.4px;
  color: var(--gray-700);
  margin-left: 4px;
  padding: 2px 6px;
  background: var(--gray-100);
  border-radius: 4px;
}
._homeWrapper_3fkd6_261 ._tableWrapper_3fkd6_276 table td ._cellInner_3fkd6_321 ._title_3fkd6_331 ._version_3fkd6_336 {
  background: var(--teal-100);
}
._homeWrapper_3fkd6_261 ._tableWrapper_3fkd6_276 table td ._cellInner_3fkd6_321 ._id_3fkd6_350 {
  font-size: 10px;
  color: #4f5162;
}
._homeWrapper_3fkd6_261 ._tableWrapper_3fkd6_276 table td ._cellInner_3fkd6_321 ._icon_3fkd6_354 {
  margin: 0 2px;
}
._homeWrapper_3fkd6_261 ._tableWrapper_3fkd6_276 table td ._cellInner_3fkd6_321 ._icon_3fkd6_354 svg {
  width: 24px;
  height: auto;
}
._homeWrapper_3fkd6_261 ._tableWrapper_3fkd6_276 table td:first-child {
  color: #000;
}
._homeWrapper_3fkd6_261 ._tableWrapper_3fkd6_276 table td ._actions_3fkd6_364 {
  justify-content: right;
  color: var(--gray-500);
}
._homeWrapper_3fkd6_261 ._tableWrapper_3fkd6_276 table td ._actions_3fkd6_364 > span {
  margin: 0 0 0 24px;
  width: 24px;
  height: 26px;
  display: inline-block;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
}
._homeWrapper_3fkd6_261 ._tableWrapper_3fkd6_276 table td ._tooptip_3fkd6_377 {
  display: inline-block;
  margin: 0 0 0 25px;
  vertical-align: middle;
}
._homeWrapper_3fkd6_261 ._tableWrapper_3fkd6_276 table td ._tooptipContent_3fkd6_382 {
  width: 164px;
  box-sizing: border-box;
  border-radius: 4px;
  background: #000;
  color: #fff;
  font-size: 12px;
  padding: 4px;
}
._homeWrapper_3fkd6_261 ._tableWrapper_3fkd6_276 table td ._cellInnerCenter_3fkd6_391 {
  justify-content: center;
}
._homeWrapper_3fkd6_261 ._reviewWrapper_3fkd6_394 {
  padding: 0 95px;
}
._homeWrapper_3fkd6_261 ._reviewWrapper_3fkd6_394 ul._viewLists_3fkd6_397 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  flex: 1 1 auto;
}
._homeWrapper_3fkd6_261 ._reviewWrapper_3fkd6_394 ul._viewLists_3fkd6_397 li {
  width: 218px;
  min-height: 278px;
  box-sizing: border-box;
  border-radius: 10px;
  border: #eee 1px solid;
  box-shadow: #e5e5e5 2px 1px 8px;
  list-style: none;
  padding: 17px 20px 0;
  margin: 16px;
  background: #fff;
}
._homeWrapper_3fkd6_261 ._reviewWrapper_3fkd6_394 ul._viewLists_3fkd6_397 li h5 {
  font-size: 14px;
  font-weight: normal;
  color: #22222a;
  margin: 0;
  padding: 10px 0 0;
}
._homeWrapper_3fkd6_261 ._reviewWrapper_3fkd6_394 ul._viewLists_3fkd6_397 li h5 ._draft_3fkd6_335,
._homeWrapper_3fkd6_261 ._reviewWrapper_3fkd6_394 ul._viewLists_3fkd6_397 li h5 ._version_3fkd6_336 {
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.4px;
  color: var(--gray-700);
  margin-left: 4px;
  padding: 2px 6px;
  background: var(--gray-100);
  border-radius: 4px;
}
._homeWrapper_3fkd6_261 ._reviewWrapper_3fkd6_394 ul._viewLists_3fkd6_397 li h5 ._version_3fkd6_336 {
  background: var(--teal-100);
}
._homeWrapper_3fkd6_261 ._reviewWrapper_3fkd6_394 ul._viewLists_3fkd6_397 li > span {
  color: #9293ab;
  font-size: 10px;
  padding: 0 0 10px;
  display: block;
}
._homeWrapper_3fkd6_261 ._reviewWrapper_3fkd6_394 ul._viewLists_3fkd6_397 li > p {
  color: #383946;
  margin: 0 0 5px;
  padding: 0;
  font-size: 12px;
}
._homeWrapper_3fkd6_261 ._reviewWrapper_3fkd6_394 ul._viewLists_3fkd6_397 li > img {
  width: 100%;
}
._homeWrapper_3fkd6_261 ._reviewWrapper_3fkd6_394 ul._viewLists_3fkd6_397 li ._viewTop_3fkd6_453 {
  display: flex;
  justify-content: space-between;
  min-height: 30px;
}
._homeWrapper_3fkd6_261 ._reviewWrapper_3fkd6_394 ul._viewLists_3fkd6_397 li ._viewTop_3fkd6_453 ._viewTopLeft_3fkd6_458 {
  display: flex;
}
._homeWrapper_3fkd6_261 ._reviewWrapper_3fkd6_394 ul._viewLists_3fkd6_397 li ._viewTop_3fkd6_453 ._viewTopRight_3fkd6_461 {
  width: 25px;
  height: 21px;
  padding-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 -10px 0 0;
  position: relative;
  text-align: center;
  cursor: pointer;
}
._homeWrapper_3fkd6_261 ._reviewWrapper_3fkd6_394 ul._viewLists_3fkd6_397 li ._viewTop_3fkd6_453 ._viewTopRight_3fkd6_461 img {
  padding: 0 10px;
  width: auto;
}
._homeWrapper_3fkd6_261 ._reviewWrapper_3fkd6_394 ul._viewLists_3fkd6_397 li ._viewTop_3fkd6_453 ._viewTopRight_3fkd6_461._isOpen_3fkd6_477 {
  background: #f3f3fa;
  border-radius: 4px;
}
._homeWrapper_3fkd6_261 ._reviewWrapper_3fkd6_394 ul._viewLists_3fkd6_397 li ._viewTop_3fkd6_453 ._viewTopRight_3fkd6_461._isOpen_3fkd6_477 ._viewPopup_3fkd6_481 {
  display: block;
}
._homeWrapper_3fkd6_261 ._reviewWrapper_3fkd6_394 ul._viewLists_3fkd6_397 li ._viewPopup_3fkd6_481 {
  position: absolute;
  left: 0;
  top: 30px;
  font-size: 13px;
  color: #22222a;
  background-color: #fff;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  width: 185px;
  display: none;
  text-align: left;
  padding: 6px 0 0;
}
._homeWrapper_3fkd6_261 ._reviewWrapper_3fkd6_394 ul._viewLists_3fkd6_397 li ._viewPopup_3fkd6_481 > div {
  padding: 16px 19px;
}
._homeWrapper_3fkd6_261 ._reviewWrapper_3fkd6_394 ul._viewLists_3fkd6_397 li ._viewPopup_3fkd6_481 > div:last-child {
  color: #c31f17;
  border-top: #c31f17 1px solid;
}
._homeWrapper_3fkd6_261 ._reviewWrapper_3fkd6_394 ul._viewLists_3fkd6_397 li ._viewPopup_3fkd6_481 img {
  width: auto;
  vertical-align: middle;
  margin: 0 10px 0 0;
}
._homeWrapper_3fkd6_261 ._reviewWrapper_3fkd6_394 ul._viewLists_3fkd6_397 li ._viewPopup_3fkd6_481 span[role=img] {
  padding: 0 10px;
  margin-right: 1px;
  font-size: 18px;
}
._homeWrapper_3fkd6_261 ._reviewWrapper_3fkd6_394 ul._viewLists_3fkd6_397 li ._icons_3fkd6_515 {
  display: flex;
  padding: 5px 0;
}
._homeWrapper_3fkd6_261 ._reviewWrapper_3fkd6_394 ul._viewLists_3fkd6_397 li ._icons_3fkd6_515 ._icon_3fkd6_354 {
  margin: 0 7px 0 0;
}
._homeWrapper_3fkd6_261 ._reviewWrapper_3fkd6_394 ul._actions_3fkd6_364 {
  position: absolute;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(40, 41, 61, 0.04), 0 8px 16px rgba(96, 97, 112, 0.16);
  border-radius: 8px;
}
._homeWrapper_3fkd6_261 ._reviewWrapper_3fkd6_394 ul._actions_3fkd6_364 li {
  list-style: none;
  padding: 16px 18px;
  font-size: 13px;
  color: #22222a;
}
._homeWrapper_3fkd6_261 ._reviewWrapper_3fkd6_394 ul._actions_3fkd6_364 li:last-child {
  border-top: 1px solid #d9dae6;
}
._homeWrapper_3fkd6_261 ._reviewWrapper_3fkd6_394 ul._actions_3fkd6_364 li img {
  margin: 0 5px 0 0;
  vertical-align: middle;
}
._homeWrapper_3fkd6_261 ._paginationWrapper_3fkd6_541 {
  padding: 20px 48px 0;
}
._homeWrapper_3fkd6_261 ._emptyPage_3fkd6_544 {
  display: flex;
  flex-direction: column;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 116px 0 0;
}
._homeWrapper_3fkd6_261 ._emptyPage_3fkd6_544 p {
  padding: 71px 0 32px;
  font-size: 16px;
  color: #9293ab;
}
._homeWrapper_3fkd6_261 ._emptyPage_3fkd6_544 img {
  width: 306px;
}

._modulesCheckbox_3fkd6_561 {
  flex-direction: column;
  display: flex;
}
._modulesCheckbox_3fkd6_561 > label {
  padding: 8px;
  margin-right: 0;
  display: flex;
  align-items: center;
}

._dropdown_3fkd6_572 {
  background: #f3f3fa;
  border: none;
  margin: 0 5px;
  padding: 5.8px 5px 5.8px 10px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 13px;
  color: #22222a;
  white-space: nowrap;
}
._dropdown_3fkd6_572 ._dropdownText_3fkd6_583 {
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #22222a;
  padding: 2px 9px;
  font-size: 10px;
}

._btns_3fkd6_592._btns_3fkd6_592 button {
  width: 83px;
}

._tooltipImg_3fkd6_596 {
  width: 100%;
  height: auto;
}

._dropdownModuleItem_3fkd6_601 {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
}
._dropdownModuleItem_3fkd6_601 ._svgDiv_3fkd6_607 > div {
  width: 24px;
  display: flex;
  align-items: center;
}
._dropdownModuleItem_3fkd6_601 svg {
  height: 100%;
}