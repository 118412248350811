._searchBar_1rko7_1 {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 56px;
  background: #fff;
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.08), 0 0.5px 2px rgba(96, 97, 112, 0.16);
  padding: 0 43px;
}
._searchBar_1rko7_1 ._searchBarLeft_1rko7_11 {
  padding: 0 20px 0 0;
  width: 125px;
  box-sizing: border-box;
  flex: 0 0 auto;
}
._searchBar_1rko7_1 ._searchBarLeft_1rko7_11 button {
  font-size: 13px;
  white-space: nowrap;
}
._searchBar_1rko7_1 ._searchBarMid_1rko7_21 {
  padding: 0 20px 0 0;
  border: 0;
  flex: 1 1 auto;
  box-sizing: border-box;
}
._searchBar_1rko7_1 ._searchRight_1rko7_27 {
  width: 310px;
  display: flex;
  justify-content: right;
  align-items: center;
  flex: 0 0 auto;
  height: 51px;
}
._searchBar_1rko7_1 ._searchRight_1rko7_27 ._searchBtn_1rko7_35 {
  padding: 7px;
  background-color: #f3f3fa;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 5px;
}
._searchBar_1rko7_1 ._searchRight_1rko7_27 ._selectBox_1rko7_45 {
  width: 150px;
  height: 32px;
  margin: 0 0 0 22px;
  flex: 0 0 auto;
}
._searchBar_1rko7_1 ._searchRight_1rko7_27 > span {
  width: 51px;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border-radius: 8px;
  margin: 0 0 0 20px;
  cursor: pointer;
}
._searchBar_1rko7_1 ._searchRight_1rko7_27 > span svg rect {
  fill: #22222a;
}
._searchBar_1rko7_1 ._searchRight_1rko7_27 > span svg path {
  fill: #22222a;
}
._searchBar_1rko7_1 ._searchRight_1rko7_27 > span._selected_1rko7_68 {
  color: #0278d5;
}
._searchBar_1rko7_1 ._searchRight_1rko7_27 > span._selected_1rko7_68 svg rect {
  fill: #0278d5;
}
._searchBar_1rko7_1 ._searchRight_1rko7_27 > span._selected_1rko7_68 svg path {
  fill: #0278d5;
}
._searchBar_1rko7_1 ._searchRight_1rko7_27 > span:hover {
  color: #0278d5;
}
._searchBar_1rko7_1 ._searchRight_1rko7_27 > span:hover svg rect {
  fill: #0278d5;
}
._searchBar_1rko7_1 ._searchRight_1rko7_27 > span:hover svg path {
  fill: #0278d5;
}

._noPermission_1rko7_87 {
  text-align: center;
  padding: 48px;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
._noPermission_1rko7_87 > img {
  width: 158px;
  height: auto;
  margin-bottom: 24px;
}

._homeWrapper_1rko7_103 {
  padding: 20px 0 0;
}
._homeWrapper_1rko7_103 ._searchBoxWrapper_1rko7_106 {
  display: flex;
  justify-content: space-between;
  padding: 40px 0 0;
  align-items: center;
}
._homeWrapper_1rko7_103 ._paginationWrapper_1rko7_112 {
  padding-top: 20px;
}
._homeWrapper_1rko7_103 ._tableWrapper_1rko7_115 {
  padding: 0 43px;
}
._homeWrapper_1rko7_103 ._tableWrapper_1rko7_115 table {
  border-spacing: 0;
  width: 100%;
  text-align: left;
}
._homeWrapper_1rko7_103 ._tableWrapper_1rko7_115 table tr :last-child td {
  border-bottom: 0;
}
._homeWrapper_1rko7_103 ._tableWrapper_1rko7_115 table th {
  font-size: 10px;
  text-align: left;
  padding-top: 20px;
  padding-bottom: 15px;
  padding-left: 48px;
}
._homeWrapper_1rko7_103 ._tableWrapper_1rko7_115 table th span {
  vertical-align: middle;
  display: inline-block;
  margin: 0 0 0 10px;
}
._homeWrapper_1rko7_103 ._tableWrapper_1rko7_115 table th ._arrows_1rko7_138 {
  display: inline-flex;
  flex-direction: column;
  vertical-align: middle;
}
._homeWrapper_1rko7_103 ._tableWrapper_1rko7_115 table th ._arrows_1rko7_138 ._arrowTop_1rko7_143 {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-bottom: 5px solid #000;
  margin-bottom: 2px;
  cursor: pointer;
}
._homeWrapper_1rko7_103 ._tableWrapper_1rko7_115 table th ._arrows_1rko7_138 ._arrowDown_1rko7_151 {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top: 5px solid #000;
  cursor: pointer;
}
._homeWrapper_1rko7_103 ._tableWrapper_1rko7_115 table td {
  padding: 5px 0;
  text-align: left;
  color: #4f5162;
}
._homeWrapper_1rko7_103 ._tableWrapper_1rko7_115 table td ._cellInner_1rko7_163 {
  padding: 5px;
  height: 65px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  background: #fff;
  display: inline-flex;
  width: 100%;
  justify-content: left;
  align-items: center;
  font-size: 13px;
  box-sizing: border-box;
  padding-left: 48px;
}
._homeWrapper_1rko7_103 ._tableWrapper_1rko7_115 table td ._cellInner_1rko7_163._multiRow_1rko7_177 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
._homeWrapper_1rko7_103 ._tableWrapper_1rko7_115 table td ._cellInner_1rko7_163._capitalized_1rko7_183 {
  text-transform: capitalize;
}
._homeWrapper_1rko7_103 ._tableWrapper_1rko7_115 table td ._cellInner_1rko7_163 ._title_1rko7_186 {
  font-size: 13px;
  color: #000;
}
._homeWrapper_1rko7_103 ._tableWrapper_1rko7_115 table td ._cellInner_1rko7_163 ._id_1rko7_190 {
  font-size: 8px;
  color: #4f5162;
}
._homeWrapper_1rko7_103 ._tableWrapper_1rko7_115 table td ._cellInner_1rko7_163 ._icon_1rko7_194 {
  margin: 0 4px;
}
._homeWrapper_1rko7_103 ._tableWrapper_1rko7_115 table td:first-child {
  color: #000;
}
._homeWrapper_1rko7_103 ._tableWrapper_1rko7_115 table td:first-child ._cellInner_1rko7_163 {
  border-left: 1px solid #e5e5e5;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
._homeWrapper_1rko7_103 ._tableWrapper_1rko7_115 table td:last-child ._cellInner_1rko7_163 {
  border-right: 1px solid #e5e5e5;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
._homeWrapper_1rko7_103 ._tableWrapper_1rko7_115 table td ._actions_1rko7_210 span {
  font-size: 18px;
  margin: 0 20px;
  width: 24px;
  height: 26px;
  display: inline-block;
  cursor: pointer;
}
._homeWrapper_1rko7_103 ._tableWrapper_1rko7_115 table td ._tooptip_1rko7_218 {
  display: inline-block;
  margin: 0 0 0 25px;
  vertical-align: middle;
}
._homeWrapper_1rko7_103 ._tableWrapper_1rko7_115 table td ._tooptipContent_1rko7_223 {
  width: 164px;
  box-sizing: border-box;
  border-radius: 4px;
  background: #000;
  color: #fff;
  font-size: 12px;
  padding: 4px;
}
._homeWrapper_1rko7_103 ._reviewWrapper_1rko7_232 {
  padding: 0 95px;
}
._homeWrapper_1rko7_103 ._reviewWrapper_1rko7_232 ul._viewLists_1rko7_235 {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  width: 100%;
  flex: 1 1 auto;
}
._homeWrapper_1rko7_103 ._reviewWrapper_1rko7_232 ul._viewLists_1rko7_235 li {
  width: 218px;
  min-height: 278px;
  box-sizing: border-box;
  border-radius: 10px;
  border: #eee 1px solid;
  box-shadow: #e5e5e5 2px 1px 8px;
  list-style: none;
  padding: 17px 20px 0;
  margin: 16px;
  background: #fff;
}
._homeWrapper_1rko7_103 ._reviewWrapper_1rko7_232 ul._viewLists_1rko7_235 li h5 {
  font-size: 14px;
  font-weight: normal;
  color: #22222a;
  margin: 0;
  padding: 10px 0 0;
}
._homeWrapper_1rko7_103 ._reviewWrapper_1rko7_232 ul._viewLists_1rko7_235 li > span {
  color: #9293ab;
  font-size: 10px;
  padding: 0 0 10px;
  display: block;
}
._homeWrapper_1rko7_103 ._reviewWrapper_1rko7_232 ul._viewLists_1rko7_235 li > p {
  color: #383946;
  margin: 0 0 5px;
  padding: 0;
  font-size: 12px;
}
._homeWrapper_1rko7_103 ._reviewWrapper_1rko7_232 ul._viewLists_1rko7_235 li > img {
  width: 100%;
}
._homeWrapper_1rko7_103 ._reviewWrapper_1rko7_232 ul._viewLists_1rko7_235 li ._viewTop_1rko7_276 {
  display: flex;
  justify-content: space-between;
  min-height: 30px;
}
._homeWrapper_1rko7_103 ._reviewWrapper_1rko7_232 ul._viewLists_1rko7_235 li ._viewTop_1rko7_276 ._viewTopLeft_1rko7_281 {
  display: flex;
}
._homeWrapper_1rko7_103 ._reviewWrapper_1rko7_232 ul._viewLists_1rko7_235 li ._viewTop_1rko7_276 ._viewTopRight_1rko7_284 {
  width: 25px;
  height: 21px;
  padding-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 -10px 0 0;
  position: relative;
  text-align: center;
  cursor: pointer;
}
._homeWrapper_1rko7_103 ._reviewWrapper_1rko7_232 ul._viewLists_1rko7_235 li ._viewTop_1rko7_276 ._viewTopRight_1rko7_284 img {
  padding: 0 10px;
  width: auto;
}
._homeWrapper_1rko7_103 ._reviewWrapper_1rko7_232 ul._viewLists_1rko7_235 li ._viewTop_1rko7_276 ._viewTopRight_1rko7_284._isOpen_1rko7_300 {
  background: #f3f3fa;
  border-radius: 4px;
}
._homeWrapper_1rko7_103 ._reviewWrapper_1rko7_232 ul._viewLists_1rko7_235 li ._viewTop_1rko7_276 ._viewTopRight_1rko7_284._isOpen_1rko7_300 ._viewPopup_1rko7_304 {
  display: block;
}
._homeWrapper_1rko7_103 ._reviewWrapper_1rko7_232 ul._viewLists_1rko7_235 li ._viewPopup_1rko7_304 {
  position: absolute;
  left: 0;
  top: 30px;
  font-size: 13px;
  color: #22222a;
  background-color: #fff;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  width: 185px;
  display: none;
  text-align: left;
  padding: 6px 0 0;
}
._homeWrapper_1rko7_103 ._reviewWrapper_1rko7_232 ul._viewLists_1rko7_235 li ._viewPopup_1rko7_304 > div {
  padding: 16px 19px;
}
._homeWrapper_1rko7_103 ._reviewWrapper_1rko7_232 ul._viewLists_1rko7_235 li ._viewPopup_1rko7_304 > div:last-child {
  color: #c31f17;
  border-top: #c31f17 1px solid;
}
._homeWrapper_1rko7_103 ._reviewWrapper_1rko7_232 ul._viewLists_1rko7_235 li ._viewPopup_1rko7_304 img {
  width: auto;
  vertical-align: middle;
  margin: 0 10px 0 0;
}
._homeWrapper_1rko7_103 ._reviewWrapper_1rko7_232 ul._viewLists_1rko7_235 li ._icons_1rko7_333 {
  display: flex;
  padding: 0 0 5px;
}
._homeWrapper_1rko7_103 ._reviewWrapper_1rko7_232 ul._viewLists_1rko7_235 li ._icons_1rko7_333 ._icon_1rko7_194 {
  margin: 0 7px 0 0;
}
._homeWrapper_1rko7_103 ._reviewWrapper_1rko7_232 ul._actions_1rko7_210 {
  position: absolute;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(40, 41, 61, 0.04), 0 8px 16px rgba(96, 97, 112, 0.16);
  border-radius: 8px;
}
._homeWrapper_1rko7_103 ._reviewWrapper_1rko7_232 ul._actions_1rko7_210 li {
  list-style: none;
  padding: 16px 18px;
  font-size: 13px;
  color: #22222a;
}
._homeWrapper_1rko7_103 ._reviewWrapper_1rko7_232 ul._actions_1rko7_210 li:last-child {
  border-top: 1px solid #d9dae6;
}
._homeWrapper_1rko7_103 ._reviewWrapper_1rko7_232 ul._actions_1rko7_210 li img {
  margin: 0 5px 0 0;
  vertical-align: middle;
}
._homeWrapper_1rko7_103 ._emptyPage_1rko7_359 {
  display: flex;
  flex-direction: column;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 116px 0 0;
}
._homeWrapper_1rko7_103 ._emptyPage_1rko7_359 p {
  padding: 71px 0 32px;
  font-size: 16px;
  color: #9293ab;
}
._homeWrapper_1rko7_103 ._emptyPage_1rko7_359 img {
  width: 306px;
}

._btns_1rko7_376._btns_1rko7_376 button {
  width: 83px;
}

._loadingDiv_1rko7_380 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90vw;
  height: 70vh;
}