._categoryEnum_6jjg0_1 ._categoryItem_6jjg0_1 {
  padding: 16px;
  border-radius: 4px;
  background: var(--Light-Background-White, #fff);
}
._categoryEnum_6jjg0_1 ._categoryItem_6jjg0_1 ._row_6jjg0_6 {
  display: grid;
  grid-template-columns: 12em 1fr;
  margin: 6px 0;
}
._categoryEnum_6jjg0_1 ._categoryItem_6jjg0_1 ._row_6jjg0_6 ._label_6jjg0_11 {
  display: flex;
  align-items: center;
}