._loginMain_1ekre_1 {
  width: 100vw;
  height: 100vh;
  display: flex;
}
._loginMain_1ekre_1 ._loginMainLeft_1ekre_6 {
  padding: 85px 0;
  min-width: 540px;
  flex-grow: 1;
  overflow-y: auto;
}
._loginMain_1ekre_1 ._loginMainLeft_1ekre_6 ._loginMainLeftInner_1ekre_12 {
  margin: 0 auto;
  width: 460px;
  padding: 0 40px;
}
._loginMain_1ekre_1 ._loginMainLeft_1ekre_6 ._loginMainLeftInner_1ekre_12 ._logo_1ekre_17 {
  display: flex;
  align-items: center;
  font-size: 22px;
}
._loginMain_1ekre_1 ._loginMainLeft_1ekre_6 ._loginMainLeftInner_1ekre_12 ._logo_1ekre_17 img,
._loginMain_1ekre_1 ._loginMainLeft_1ekre_6 ._loginMainLeftInner_1ekre_12 ._logo_1ekre_17 svg {
  margin-right: 8px;
}
._loginMain_1ekre_1 ._loginMainLeft_1ekre_6 ._loginMainLeftInner_1ekre_12 h2 {
  font-size: 32px;
  font-weight: 600;
  padding: 50px 0 10px;
  margin: 0;
  color: #000;
}
._loginMain_1ekre_1 ._loginMainLeft_1ekre_6 ._loginMainLeftInner_1ekre_12 h2 span {
  color: var(--primary-8);
}
._loginMain_1ekre_1 ._loginMainLeft_1ekre_6 ._loginMainLeftInner_1ekre_12 p {
  font-size: 16px;
  margin: 0;
  color: rgb(85, 82, 112);
  padding: 0 0 50px;
}
._loginMain_1ekre_1 ._loginMainLeft_1ekre_6 ._loginMainLeftInner_1ekre_12 ._notification_1ekre_42 {
  border: red 1px solid;
  border-radius: 5px;
  color: red;
  padding: 10px;
  margin: 0 0 20px;
}
._loginMain_1ekre_1 ._loginMainLeft_1ekre_6 ._loginMainLeftInner_1ekre_12 ._lineWrapper_1ekre_49 {
  padding: 0 0 15px;
}
._loginMain_1ekre_1 ._loginMainLeft_1ekre_6 ._loginMainLeftInner_1ekre_12 ._lineWrapper_1ekre_49 a {
  display: block;
  text-decoration: none;
}
._loginMain_1ekre_1 ._loginMainLeft_1ekre_6 ._loginMainLeftInner_1ekre_12 ._lineWrapper_1ekre_49 button {
  display: block;
  width: 100%;
}
._loginMain_1ekre_1 ._loginMainLeft_1ekre_6 ._loginMainLeftInner_1ekre_12 label {
  font-size: 14px;
  color: #1a1a1a;
  display: block;
  padding: 0 0 10px;
}
._loginMain_1ekre_1 ._loginMainLeft_1ekre_6 ._loginMainLeftInner_1ekre_12 label a {
  float: right;
  color: rgb(2, 120, 213);
  text-decoration: none;
}
._loginMain_1ekre_1 ._loginMainLeft_1ekre_6 ._loginMainLeftInner_1ekre_12 ._notice_1ekre_71 {
  display: block;
  font-size: 12px;
  color: rgb(96, 97, 112);
  padding: 10px 0 0;
}
._loginMain_1ekre_1 ._loginMainLeft_1ekre_6 ._loginMainLeftInner_1ekre_12 ._or_1ekre_77 {
  border-bottom: rgb(85, 87, 112) 1px solid;
  text-align: center;
  line-height: 1px;
  margin: 50px 0 0;
}
._loginMain_1ekre_1 ._loginMainLeft_1ekre_6 ._loginMainLeftInner_1ekre_12 ._or_1ekre_77 span {
  background-color: #fff;
  color: rgb(85, 87, 112);
  padding: 0 12px;
}
._loginMain_1ekre_1 ._loginMainLeft_1ekre_6 ._loginMainLeftInner_1ekre_12 ._links_1ekre_88 {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  justify-content: center;
  padding: 23px 0 0;
}
._loginMain_1ekre_1 ._loginMainLeft_1ekre_6 ._loginMainLeftInner_1ekre_12 ._links_1ekre_88 a {
  width: 46px;
  height: 49px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: rgb(242, 242, 245);
}
._loginMain_1ekre_1 ._loginMainLeft_1ekre_6 ._loginMainLeftInner_1ekre_12 ._links_1ekre_88 a:hover {
  background-color: rgb(228, 228, 235);
}
._loginMain_1ekre_1 ._loginMainLeft_1ekre_6 ._loginMainLeftInner_1ekre_12 ._links_1ekre_88 button {
  width: 100%;
  height: 48px;
  border-color: var(--primary-7);
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: var(--primary-7);
  display: flex;
  align-items: center;
  justify-content: center;
}
._loginMain_1ekre_1 ._loginMainLeft_1ekre_6 ._loginMainLeftInner_1ekre_12 ._links_1ekre_88 button:hover {
  color: var(--primary-8);
}
._loginMain_1ekre_1 ._loginMainLeft_1ekre_6 ._loginMainLeftInner_1ekre_12 ._links_1ekre_88 button img {
  width: 24px;
  height: 24px;
  margin-right: 6px;
}
._loginMain_1ekre_1 ._loginMainLeft_1ekre_6 ._loginMainLeftInner_1ekre_12 ._linksBtm_1ekre_128 a {
  display: flex;
  width: 100%;
  height: 44px;
  align-items: center;
  justify-content: center;
  background-color: rgb(242, 242, 245);
  margin: 20px 0 0;
  font-size: 14px;
  color: #000;
  text-decoration: none;
  border-radius: 10px;
}
._loginMain_1ekre_1 ._loginMainLeft_1ekre_6 ._loginMainLeftInner_1ekre_12 ._linksBtm_1ekre_128 a:hover {
  background-color: rgb(228, 228, 235);
}
._loginMain_1ekre_1 ._loginMainLeft_1ekre_6 ._loginMainLeftInner_1ekre_12 ._linksBtm_1ekre_128 img {
  width: 24px;
  height: 24px;
  margin: 0 10px 0 0;
}
._loginMain_1ekre_1 ._loginMainLeft_1ekre_6 ._loginMainLeftInner_1ekre_12 ._btmText_1ekre_149 {
  padding: 50px 0 0;
  font-size: 14px;
}
._loginMain_1ekre_1 ._loginMainLeft_1ekre_6 ._loginMainLeftInner_1ekre_12 ._btmText_1ekre_149 a {
  color: rgb(2, 120, 213);
}
._loginMain_1ekre_1 ._loginMainRight_1ekre_156 {
  background: #0a3364;
  height: 100vh;
  flex-grow: 1;
  display: flex;
  max-width: 65%;
  align-items: center;
  justify-content: center;
}
._loginMain_1ekre_1 ._loginMainRight_1ekre_156 img {
  width: 75%;
  height: auto;
}
._loginMain_1ekre_1 ._bgDotBlue_1ekre_169 {
  background: linear-gradient(90deg, #07182b 9px, transparent 1%) center, linear-gradient(#07182b 9px, transparent 1%) center, var(--gray-500);
  background-size: 10px 10px;
  box-shadow: inset 0 6px 25px rgba(133, 96, 96, 0.04);
}