._col2Container_9ef6s_1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}

._summaryDesc_9ef6s_7 {
  color: var(--gray-500);
  margin-bottom: 24px;
}

._chartSectionTitle_9ef6s_12 {
  display: flex;
  align-items: center;
  margin: 20px 0;
}
._chartSectionTitle_9ef6s_12 > div {
  margin-right: 6px;
}
._chartSectionTitle_9ef6s_12 div {
  display: flex;
  align-items: center;
}
._chartSectionTitle_9ef6s_12 svg,
._chartSectionTitle_9ef6s_12 img {
  height: 28px;
  width: auto;
}

h3 {
  margin-top: 20px;
}

._chartCard_9ef6s_34 {
  margin-top: 16px;
  padding: 8px;
  background: #fff;
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
  border-radius: 4px;
}
._chartCard_9ef6s_34 ._perctImprovement_9ef6s_41 {
  background-color: rgba(0, 176, 240, 0.46);
  border-radius: 4px;
  padding: 8px;
  text-align: center;
  font-weight: 500;
  margin-top: 8px;
}

._moduleChartContainer_9ef6s_50,
._moduleChartContainerCI_9ef6s_51,
._moduleChartContainerCD_9ef6s_52,
._moduleChartContainerFF_9ef6s_53,
._moduleChartContainerCC_9ef6s_54,
._moduleChartContainerSRM_9ef6s_55,
._moduleChartContainerSTO_9ef6s_56,
._moduleChartContainerCE_9ef6s_57 {
  padding: 24px 48px 48px;
  margin-top: 24px;
}

._moduleChartContainerCI_9ef6s_51 {
  background-color: var(--ci-theme-bg);
}

._moduleChartContainerCD_9ef6s_52 {
  background-color: var(--cd-theme-bg);
}

._moduleChartContainerFF_9ef6s_53 {
  background-color: var(--ff-theme-bg);
}

._moduleChartContainerCC_9ef6s_54 {
  background-color: var(--cc-theme-bg);
}

._moduleChartContainerSRM_9ef6s_55 {
  background-color: var(--srm-theme-bg);
}

._moduleChartContainerSTO_9ef6s_56 {
  background-color: var(--sto-theme-bg);
}

._moduleChartContainerCE_9ef6s_57 {
  background-color: var(--ce-theme-bg);
}