._categoryEnum_5flwn_1 ._categoryItem_5flwn_1 {
  background: var(--Light-Background-White, #fff);
  padding: 8px 16px;
  border-radius: 8px;
}
._categoryEnum_5flwn_1 ._categoryItem_5flwn_1 ._fieldsBinRow_5flwn_6 {
  display: flex;
  gap: 8px;
  align-items: center;
}
._categoryEnum_5flwn_1 ._categoryItem_5flwn_1 ._fieldsBinRow_5flwn_6 ._row_5flwn_11 {
  display: grid;
  grid-template-columns: 12em 1fr;
  margin: 6px 0;
}
._categoryEnum_5flwn_1 ._categoryItem_5flwn_1 ._fieldsBinRow_5flwn_6 ._row_5flwn_11 ._label_5flwn_16 {
  display: flex;
  align-items: center;
}
._categoryEnum_5flwn_1 ._deleteButton_5flwn_20 {
  border: transparent;
}
._categoryEnum_5flwn_1 ._addButton_5flwn_23 {
  width: 100%;
  margin-top: 16px;
}