@charset "UTF-8";
@keyframes _heartbeat_w093v_1 {
  0% {
    scale: 0.8;
    opacity: 0.6;
  }
  40% {
    scale: 1.2;
    opacity: 1;
  }
  100% {
    scale: 0.8;
    opacity: 0.6;
  }
}
._step3Result_w093v_16 {
  position: absolute;
  right: 12px;
  bottom: 104px;
  width: 300px;
  background: #fff;
  box-shadow: #e5e5e5 1px 1px 10px;
  border: 1px solid #e5e5e5;
  padding: 10px;
  max-height: calc(100vh - 138px);
  overflow-y: auto;
}
._step3Result_w093v_16._isFixed_w093v_28 {
  position: fixed;
  top: 0;
}
._step3Result_w093v_16 ._collapse_w093v_32 {
  background-color: transparent;
}
._step3Result_w093v_16 ._resultTop_w093v_35 {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-size: 14px;
  color: #004ba4;
  padding: 16px 0;
}
._step3Result_w093v_16 ._previewModuleTitle_w093v_43 {
  display: flex;
  align-items: center;
}
._step3Result_w093v_16 ._previewModuleTitle_w093v_43 h5 {
  margin-left: 6px;
  line-height: 105%;
}
._step3Result_w093v_16 ._previewModuleTitle_w093v_43 svg {
  width: 28px;
  height: 28px;
}
._step3Result_w093v_16 h5 {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  padding: 12px 0 15px;
  text-transform: capitalize;
}
._step3Result_w093v_16 ._previewItem_w093v_62 p {
  text-transform: capitalize;
}
._step3Result_w093v_16._cd_w093v_65 li > span {
  color: #42ab45;
}
._step3Result_w093v_16._ci_w093v_68 li > span {
  color: #17a4f4;
}
._step3Result_w093v_16._ff_w093v_71 li > span {
  color: #e76e34;
}
._step3Result_w093v_16 ul {
  border-top: #e9d3e7 1px solid;
  padding: 25px 0 0;
}
._step3Result_w093v_16 ul li {
  list-style: none;
  font-size: 10px;
  line-height: 24px;
  color: #4f5162;
  padding: 0 0 16px;
}
._step3Result_w093v_16 ul li span {
  font-size: 20px;
}
._step3Result_w093v_16 ul li p {
  padding: 0 0 10px;
  font-size: 12px;
  line-height: 14px;
  color: #535162;
  font-weight: normal;
}
._step3Result_w093v_16 .ant-collapse-borderless > .ant-collapse-item {
  border-bottom: none;
}

._minus_w093v_99 {
  color: var(--red) !important;
}
._minus_w093v_99 span svg[data-icon=warning] {
  color: var(--red) !important;
}

._low_w093v_106 {
  color: var(--orange) !important;
}
._low_w093v_106 span svg[data-icon=info-circle] {
  color: var(--orange) !important;
}

._sectionList_w093v_113 {
  position: absolute;
  right: 24px;
  top: 150px;
  max-width: 268px;
  width: 268px;
}
._sectionList_w093v_113 li {
  border-bottom: none !important;
  border-left: 1px solid #b0b1c3;
}
._sectionList_w093v_113 li a {
  color: #22222a;
}
._sectionList_w093v_113 li a._active_w093v_127 {
  color: #0278d5;
  font-weight: 500;
}
._sectionList_w093v_113 li a._active_w093v_127::before {
  content: "➤ ";
}
._sectionList_w093v_113 li._activeItem_w093v_134 {
  display: block;
  background-color: #42ab45;
}

._sectionResult_w093v_139 {
  position: reset;
}
._sectionResult_w093v_139 h5 {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  padding: 0 0 12px;
  text-transform: capitalize;
}
._sectionResult_w093v_139._cd_w093v_65 li > span {
  color: #42ab45;
}
._sectionResult_w093v_139._ci_w093v_68 li > span {
  color: #17a4f4;
}
._sectionResult_w093v_139._ff_w093v_71 li > span {
  color: #e76e34;
}
._sectionResult_w093v_139 ul {
  border-top: #e9d3e7 1px solid;
  padding: 25px 0 0;
}
._sectionResult_w093v_139 ul li {
  list-style: none;
  font-size: 10px;
  line-height: 24px;
  color: #4f5162;
  padding: 0 0 16px;
}
._sectionResult_w093v_139 ul li span {
  font-size: 20px;
}
._sectionResult_w093v_139 ul li p {
  padding: 0 0 10px;
  font-size: 12px;
  line-height: 14px;
  color: #535162;
  font-weight: normal;
}